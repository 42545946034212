<template>
  <div class="investments-investment-edit-form">
    <vs-form
      v-model="investment"
      :errors="errors"
      i18n-namespace="investments.form.investment.field"
      no-padding
    >

      <vs-form-header
        :text="$t('investments.form.investment.tab.file.section.details')"
      />

      <vs-form-group
        field="name"
        cols="9"
        labelCols="4"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.name')"
            v-model="investment.name"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="shortcut"
        noLabel
        cols="3"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.shortcut')"
            v-model="investment.shortcut"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="core_branch_id"
        >
        <core-branch-picker
          v-model="investment.core_branch_id"
        />
      </vs-form-group>

      <vs-form-group
        field="crm_investor_id"
        integration="crm_address_relation"
        >
        <crm-client-picker
          v-model="investment.crm_investor_id"
          :reduce="client => client.id"
        />
      </vs-form-group>

      <vs-form-group
        field="crm_principal_id"
        integration="crm_address_relation"
        >
        <crm-client-picker
          v-model="investment.crm_principal_id"
          :reduce="client => client.id"
        />
      </vs-form-group>

      <vs-form-group
        field="external_contract"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.external_contract')"
            v-model="investment.external_contract"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="serial"
        >
        <div class="editable-spans">
          <span
            contenteditable
            spellcheck="false"
            @blur="(e) => updateSerialKey(e, 'id')"
          >{{ serial.id }}</span>

          <span>/{{ serial.branch || 'X' }}/</span>

          <span
            contenteditable
            spellcheck="false"
            @blur="(e) => updateSerialKey(e, 'year')"
          >{{ serial.year }}</span>

          <span>/{{ serial.shortcut || 'X' }}/</span>

          <span
            contenteditable
            spellcheck="false"
            @blur="(e) => updateSerialKey(e, 'provider')"
          >{{ serial.provider }}</span>
        </div>
      </vs-form-group>

      <vs-form-group
        field="offer"
        >

        <div class="info" v-if="offer">
          Aktualna oferta
          (<a :href="offer.file_link">{{ offer.media.original_name}}</a>)
          została przesłana w dniu
          {{ formatDate.training(offer.media.created_at) }}.
          Przesłanie nowszego pliku nie spowoduje usunięcia obecnej oferty.

          <div class="info-upload-new" v-if="!replacingOffer">
            <vs-button
              vs-variant="light"
              @click="replacingOffer = true"
              >
              Zaktualizuj ofertę
            </vs-button>
          </div>
        </div>

        <vs-file
          v-if="!offer || replacingOffer"
          v-model="investment.offer"
          placeholder="Wybierz aktualną ofertę"
          class="file-upload"
        />
      </vs-form-group>

      <vs-form-group
        field="investment_status_id"
        >
        <investments-status-picker
          v-model="investment.investment_status_id"
        />
      </vs-form-group>

      <vs-form-header :text="$t('investments.form.investment.tab.file.section.location')" />

      <vs-form-group
        field="street"
        labelCols="12"
        cols="8"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.street')"
            v-model="investment.street"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="post_code"
        labelCols="12"
        cols="4"
        >
        <b-input-group>
          <b-form-input
            placeholder="82-300"
            v-model="investment.post_code"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="city"
        labelCols="12"
        cols="8"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.city')"
            v-model="investment.city"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="plot_number"
        labelCols="12"
        cols="4"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.plot_number')"
            v-model="investment.plot_number"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="latitude"
        labelCols="12"
        cols="6"
        >
        <b-input-group>
          <b-form-input
            placeholder="54.139691429860456"
            v-model="investment.latitude"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="longitude"
        labelCols="12"
        cols="6"
        >
        <b-input-group>
          <b-form-input
            placeholder="19.44957203068522"
            v-model="investment.longitude"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="address_description"
        labelCols="12"
        >
        <b-form-textarea
          v-model="investment.address_description"
        />
      </vs-form-group>

      <div class="split">
        <div class="split-part">
          <vs-form-header
            :text="$t('investments.form.investment.tab.file.section.contractDeadline')"
            preserveMargin
          />

          <vs-form-group
            field="contract_scheduler.start_date"
            labelCols="12"
            >
            <vs-datepicker
              placeholder="Wybierz datę"
              v-model="investment.contract_scheduler.start_date"
            />
          </vs-form-group>

          <vs-form-group
            field="contract_scheduler.end_date"
            labelCols="12"
            >
            <vs-datepicker
              placeholder="Wybierz datę"
              v-model="investment.contract_scheduler.end_date"
            />
          </vs-form-group>

          <vs-form-group
            field="contract_scheduler.documentation_date"
            labelCols="12"
            >
            <vs-datepicker
              placeholder="Wybierz datę"
              v-model="investment.contract_scheduler.documentation_date"
            />
          </vs-form-group>

        </div>

        <div class="split-part">
          <vs-form-header
            :text="$t('investments.form.investment.tab.file.section.estimateDeadline')"
            preserveMargin
          />

          <vs-form-group
            field="estimated_scheduler.start_date"
            labelCols="12"
            >
            <vs-datepicker
              placeholder="Wybierz datę"
              v-model="investment.estimated_scheduler.start_date"
            />
          </vs-form-group>

          <vs-form-group
            field="estimated_scheduler.end_date"
            labelCols="12"
            >
            <vs-datepicker
              placeholder="Wybierz datę"
              v-model="investment.estimated_scheduler.end_date"
            />
          </vs-form-group>

          <vs-form-group
            field="estimated_scheduler.documentation_date"
            labelCols="12"
            >
            <vs-datepicker
              placeholder="Wybierz datę"
              v-model="investment.estimated_scheduler.documentation_date"
            />
          </vs-form-group>
        </div>
      </div>

    </vs-form>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import {
  computed, reactive, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import store from '@/store';
import CrmClientPicker from '@/components/module/crm/CrmClientPicker.vue';
import formatDate from '@/libs/date-formatter';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import InvestmentsStatusPicker from '@/components/module/investments/InvestmentsStatusPicker.vue';
import VsFile from '@/components/ui/vs-file/VsFile.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';

export default {
  name: 'InvestmentsEditForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    offer: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const investment = computed(() => props.value);
    const errors = ref({});

    const localSerial = reactive({
      id: null,
      branch: null,
      year: new Date().getFullYear(),
      shortcut: null,
      provider: null,
    });

    const serial = computed({
      get() {
        if (!investment.value.serial) return {};
        const serialParts = investment.value?.serial?.split('/');

        [
          localSerial.id,
          localSerial.branch,
          localSerial.year,
          localSerial.shortcut,
          localSerial.provider,
        ] = serialParts;

        return localSerial;
      },
      set() {
        return localSerial;
      },
    });

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    watch(localSerial, () => {
      const {
        id, branch, year, shortcut, provider,
      } = localSerial;

      const serialNumber = `${id}/${branch}/${year}/${shortcut}/${provider}`;

      emit('updateSerial', serialNumber);
    });

    watch(() => investment.value.core_branch_id, () => {
      const branch = store.getters['branches/byId'](investment.value.core_branch_id);
      localSerial.branch = branch.shortcut;
    });

    watch(() => investment.value.shortcut, () => {
      localSerial.shortcut = investment.value.shortcut;
    });

    watch(() => investment.value.localSerial, () => {
    });

    const updateSerialKey = (e, key) => {
      e.preventDefault();
      localSerial[key] = e.target.innerHTML;
      // eslint-disable-next-line no-param-reassign
      e.target.innerHTML = localSerial[key];
    };

    const replacingOffer = ref(false);

    return {
      investment,
      errors,
      localSerial,
      serial,
      updateSerialKey,
      formatDate,
      replacingOffer,
    };
  },
  components: {
    BInputGroup,
    BFormInput,
    BFormTextarea,
    VsForm,
    VsFormGroup,
    VsFormHeader,
    CoreBranchPicker,
    CrmClientPicker,
    VsButton,
    InvestmentsStatusPicker,
    VsFile,
    VsDatepicker,
  },
};
</script>

<style lang="sass" scoped>
.info-upload-new,
.file-upload
  margin-top: 10px

.editable-spans
  display: flex
  margin-top: 5px
  font-size: 12pt

  input
    width: 1em

  span
    display: block
    position: relative
    opacity: 0.7
    min-width: 10px

    &:empty
      min-width: 50px
      background: rgba(128, 128, 128, 0.1)
      border-radius: 5px

    &[contenteditable="true"]
      opacity: 1
      font-weight: 400

      &:focus
        outline: none
        font-weight: 500

      &:not(:empty):after
        left: 0
        display: block
        content: ''
        position: absolute
        background: orange
        width: 100%
        height: 1px

.localSerial-persistent
  text-align: center
  width: 10px

.split
  display: flex
  align-items: flex-start
  width: 100%
  justify-content: space-between

  &-part
    width: 90%
</style>
