import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('investmentsStatus', () => {
  const state = ref([
    { id: 1, name: 'Planowana', color: '#2a63ea' },
    { id: 2, name: 'W toku', color: '#ffbf27' },
    { id: 3, name: 'Zakończona', color: '#33cd85' },
  ]);

  return {
    state,
  };
});
