<template>
  <div class="investments-edit-employee-modal">
    <b-modal
      id="modal-investments-edit-employee"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.employee.edit.title')"

      v-model="investmentEditEmployeeModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.employee.edit.loading')"
        v-if="isLoading"
      />

      <investments-assign-employee-form editing
        v-model="fields"
        :feedback="feedback"
        :investment-id="investmentId"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.employee.edit.cancel')"
          :text-ok="$t('investments.modal.employee.edit.confirm')"

          @ok="editEmployee"
          @cancel="investmentEditEmployeeModal.visible = false"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { EditEmployeeError, EditEmployeeSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsAssignEmployeeForm from '../forms/InvestmentsAssignEmployeeForm.vue';

export default {
  name: 'InvestmentsAssignEmployeeModal',
  props: {
    investmentId: {
      type: Number,
    },
  },
  setup(_, { emit }) {
    const {
      investmentEditEmployeeModal,
    } = useInvestmentsStore();

    const isLoading = ref(false);

    const {
      fields, feedback, resetFields, assignFields,
      assignServerSideErrors, nonEmptyFields,
    } = useValidatedForm({
      id: '',
      brigade_id: 'required|integer',
      cadre_employee_id: 'required|integer',
      cadre_position_id: 'required|integer',
      resource_data: {
        scheduler: {
          start_date: 'required',
          end_date: 'required|date_after:@start_date',
        },
        days_number: 'required',
      },
    }, 'investments.form.investment.tab.cadre.field');

    watch(() => investmentEditEmployeeModal.value.visible, () => {
      const { data } = investmentEditEmployeeModal.value;
      if (data && Object.keys(data).length === 0) return;

      const editingValues = { ...investmentEditEmployeeModal.value.data };
      assignFields(editingValues);

      investmentEditEmployeeModal.value.data = {};
    });

    const editEmployee = async () => {
      isLoading.value = true;

      useInvestmentsApi()
        .editEmployee(fields.value.brigade_id, fields.value.cadre_employee_id, {
          ...nonEmptyFields.value,
        })
        .then(({ data }) => {
          showToast(EditEmployeeSuccess, { name: fields.value.work_days });
          emit('success', data.data);

          investmentEditEmployeeModal.value.visible = false;

          resetFields();
          isLoading.value = false;
        })
        .catch(({ response }) => {
          assignServerSideErrors(response?.data?.errors);
          showToast(EditEmployeeError);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentEditEmployeeModal,
      isLoading,
      editEmployee,
    };
  },
  components: {
    VsLoader,
    InvestmentsAssignEmployeeForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: flex-end
</style>
