var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-resource-details"
  }, [_c('investments-resource-details-list', {
    attrs: {
      "investment": _vm.investment,
      "resources": _vm.localResources
    },
    on: {
      "materialDeleted": _vm.onResourceDelete
    }
  }), _c('investments-assign-resource-modal', {
    attrs: {
      "investment-id": _vm.investment.id
    },
    on: {
      "success": _vm.addResource
    }
  }), _c('investments-edit-resource-modal', {
    on: {
      "success": _vm.editResource
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }