import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

const { fetchPickerReinforcements } = useInvestmentsApi();

export default defineStore('investmentsReinforcement', () => {
  const { state } = useRemoteResource('investments.reinforcement', fetchPickerReinforcements);

  return {
    state,
  };
});
