var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-brigade-picker vs-picker"
  }, [_c('v-select', {
    attrs: {
      "filter": _vm.fuseSearch,
      "multiple": _vm.multiple,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.filteredBrigades,
      "clearable": _vm.clearable,
      "reduce": _vm.reduce,
      "label": _vm.label,
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var name = _ref.name,
          is_management = _ref.is_management;
        return [_vm._v(" " + _vm._s(name) + " "), is_management ? [_vm._v("(brygada kierownicza)")] : _vm._e()];
      }
    }, {
      key: "selected-option",
      fn: function (_ref2) {
        var name = _ref2.name,
          is_management = _ref2.is_management;
        return [_vm._v(" " + _vm._s(name) + " "), is_management ? [_vm._v("(brygada kierownicza)")] : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('component.picker.brigade.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }