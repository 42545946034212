<template>
  <div class="investments-assign-subject-form">
    <vs-form
      v-model="subject"
      :errors="errors"
      i18nNamespace="investments.form.investment.tab.subject.field"
    >

      <vs-form-group
        field="base_technology_id"
        integration="base_technology_relation"
        >
        <base-technology-picker
          v-model="subject.base_technology_id"
        />
      </vs-form-group>

      <vs-form-group
        field="diameter"
        >
        <b-input-group>
          <template #append>
            <b-input-group-text>mm</b-input-group-text>
          </template>

          <b-form-input
            :placeholder="$t('investments.form.investment.tab.subject.field.diameter')"
            v-model="subject.diameter"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="length"
        cols="9"
        labelCols="4"
        >
        <b-input-group append="m">
          <b-form-input
            :placeholder="$t('investments.form.investment.tab.subject.field.length')"
            v-model="subject.length"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="quantity"
        cols="3"
        noLabel
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.tab.subject.field.quantity')"
            v-model="subject.quantity"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="investment_subject_reinforcement_id"
        >
        <investments-reinforcement-picker
          v-model="subject.investment_subject_reinforcement_id"
          :reduce="({ id }) => id"
        />
      </vs-form-group>

    </vs-form>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupText,
  BFormInput,
} from 'bootstrap-vue';
import {
  computed, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import BaseTechnologyPicker from '@/components/module/base/BaseTechnologyPicker.vue';
import InvestmentsReinforcementPicker from '@/components/module/investments/InvestmentsReinforcementPicker.vue';

export default {
  name: 'InvestmentsAssignSubjectForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const subject = computed(() => props.value);
    const errors = ref({});

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    return {
      subject,
      errors,
    };
  },
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupText,
    VsForm,
    VsFormGroup,
    BaseTechnologyPicker,
    InvestmentsReinforcementPicker,
  },
};
</script>

<style lang="sass" scoped>
.editable-spans
  display: flex
  margin-top: 5px
  font-size: 12pt

  input
    width: 1em

  span
    display: block
    position: relative
    opacity: 0.7
    min-width: 10px

    &:empty
      min-width: 50px
      background: rgba(128, 128, 128, 0.1)
      border-radius: 5px

    &[contenteditable="true"]
      opacity: 1
      font-weight: 400

      &:focus
        outline: none
        font-weight: 500

      &:not(:empty):after
        left: 0
        display: block
        content: ''
        position: absolute
        background: orange
        width: 100%
        height: 1px

.serial-persistent
  text-align: center
  width: 10px
</style>
