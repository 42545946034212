<template>
  <div class="investments-material-offer-form">
    <vs-form
      v-model="offer"
      :errors="errors"
      i18nNamespace="investments.form.investment.tab.offer.field"
    >

      <vs-form-group
        field="investment_requirement_group_id"
        >
        <investments-material-picker
          v-model="offer.investment_requirement_group_id"
          :reduce="material => material.id"
        />
      </vs-form-group>

      <vs-form-group
        field="crm_provider_id"
        integration="crm_address_relation"
        >
        <crm-provider-picker
          v-model="offer.crm_provider_id"
        />
      </vs-form-group>

      <vs-form-group
        field="valid_date"
        >
        <vs-datepicker
          placeholder="Wybierz datę"
          v-model="validDate"
        />
      </vs-form-group>

      <vs-form-group
        field="guarantee_price_date"
        >

        <b-form-checkbox
          v-model="offer.whole_period"
          class="period-checkbox"
          >
          Cały okres robót
        </b-form-checkbox>

        <vs-datepicker v-if="!offer.whole_period"
          placeholder="Wybierz datę"
          v-model="warrantyDate"
        />
      </vs-form-group>

      <vs-form-group
        field="value"
        >
        <b-input-group append="PLN">
          <b-form-input
            :placeholder="$t('investments.form.investment.tab.offer.field.value')"
            v-model="offer.value"
          />
        </b-input-group>
      </vs-form-group>
    </vs-form>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import {
  computed, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import formatDate from '@/libs/date-formatter';
import InvestmentsMaterialPicker from '@/components/module/investments/InvestmentsMaterialPicker.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';

export default {
  name: 'InvestmentsMaterialDemandForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const offer = computed(() => props.value);
    const errors = ref({});

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    const validDate = computed({
      get() {
        return formatDate.standard(offer.value.valid_date);
      },
      set(newDate) {
        offer.value.valid_date = newDate;
      },
    });

    const warrantyDate = computed({
      get() {
        return formatDate.standard(offer.value.guarantee_price_date);
      },
      set(newDate) {
        offer.value.guarantee_price_date = newDate;
      },
    });

    return {
      validDate,
      warrantyDate,
      offer,
      errors,
    };
  },
  components: {
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    VsForm,
    VsFormGroup,
    InvestmentsMaterialPicker,
    CrmProviderPicker,
    VsDatepicker,
  },
};
</script>

<style lang="sass" scoped>
.period-checkbox
  margin: 7px 0
</style>
