var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-brigade-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.investment.tab.brigade.field"
    },
    model: {
      value: _vm.brigade,
      callback: function ($$v) {
        _vm.brigade = $$v;
      },
      expression: "brigade"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.brigade.field.name')
    },
    model: {
      value: _vm.brigade.name,
      callback: function ($$v) {
        _vm.$set(_vm.brigade, "name", $$v);
      },
      expression: "brigade.name"
    }
  }), _c('b-form-checkbox', {
    staticClass: "management-checkbox",
    model: {
      value: _vm.brigade.is_management,
      callback: function ($$v) {
        _vm.$set(_vm.brigade, "is_management", $$v);
      },
      expression: "brigade.is_management"
    }
  }, [_vm._v(" Oznacz brygadę jako kierowniczą ")])], 1), _c('vs-form-group', {
    attrs: {
      "field": "investment_base_vehicles",
      "integration": "base_resource_investment_relation",
      "info": "Maszyna musi zostać najpierw dodana do inwestycji w jej ustawieniach"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "machine-checkbox",
    model: {
      value: _vm.hasVehicle,
      callback: function ($$v) {
        _vm.hasVehicle = $$v;
      },
      expression: "hasVehicle"
    }
  }, [_vm._v(" Przypisz brygadę do maszyny ")]), _vm.hasVehicle ? _c('div', {
    staticClass: "vehicles"
  }, [_c('div', {
    staticClass: "vehicle"
  }, [_c('label', {
    staticClass: "vehicle-label"
  }, [_vm._v("Maszyna główna")]), _c('base-resource-picker', {
    attrs: {
      "excluded": _vm.shouldResourceBeExcluded
    },
    model: {
      value: _vm.brigade.main_vehicle,
      callback: function ($$v) {
        _vm.$set(_vm.brigade, "main_vehicle", $$v);
      },
      expression: "brigade.main_vehicle"
    }
  })], 1), _c('div', {
    staticClass: "vehicle"
  }, [_c('label', {
    staticClass: "vehicle-label"
  }, [_vm._v("Zasoby dodatkowe")]), _c('base-resource-picker', {
    attrs: {
      "multiple": "",
      "excluded": _vm.shouldExtraResourceBeExcluded
    },
    model: {
      value: _vm.brigade.additional_vehicles,
      callback: function ($$v) {
        _vm.$set(_vm.brigade, "additional_vehicles", $$v);
      },
      expression: "brigade.additional_vehicles"
    }
  })], 1)]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }