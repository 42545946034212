<template>
  <div class="investments-brigade-picker vs-picker">
    <v-select
      v-model="selected"
      :filter="fuseSearch"
      :multiple="multiple"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="filteredBrigades"
      :clearable="clearable"
      :reduce="reduce"
      :label="label"
      :disabled="disabled"
    >
      <template #option="{ name, is_management }">
        {{ name }}
        <template v-if="is_management">(brygada kierownicza)</template>
      </template>

      <template #selected-option="{ name, is_management }">
        {{ name }}
        <template v-if="is_management">(brygada kierownicza)</template>
      </template>

      <template #no-options>
        {{ $t('component.picker.brigade.noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import vSelect from 'vue-select';
import { computed, watch } from 'vue';
import store from '@/store';

export default {
  name: 'InvestmentsBrigadePicker',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customSet: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    reduce: {
      type: Function,
      default: (option) => option,
    },
    label: {
      type: String,
      default: 'name',
    },
    investmentId: {
      type: Number,
    },
    withMainVehicleOnly: {
      type: Boolean,
    },
    autoselect: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const brigades = computed(() => store.state.investments.brigades.brigades);

    const filteredBrigades = computed(() => {
      if (!props.investmentId) return brigades.value;

      return brigades.value.filter((brigade) => {
        if (props.withMainVehicleOnly
          && !brigade.resource.some(({ pivot }) => pivot.is_main)) return false;

        return brigade.investment_id === props.investmentId;
      });
    });

    watch(() => filteredBrigades.value, () => {
      if (props.autoselect) emit('input', props.reduce(filteredBrigades.value?.[0] ?? {}));
    });

    store.dispatch('investments/brigades/fetch');

    const selected = computed({
      get() {
        return props.value;
      },
      set(value) {
        if (!value) return;

        emit('input', value);
      },
    });

    const fuseSearch = (options, search) => {
      const fuse = new Fuse(options, {
        keys: ['resource.name'],
        threshold: 0.2,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    };

    return {
      selected, filteredBrigades, fuseSearch,
    };
  },
  components: {
    vSelect,
  },
};
</script>
