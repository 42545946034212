var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-title"
  }, [_c('div', {
    staticClass: "tab-title-icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.icon,
      "size": "22"
    }
  })], 1), _c('div', {
    staticClass: "tab-title-text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), false ? _c('div', {
    staticClass: "tab-title-text-unsaved"
  }, [_c('div', {
    staticClass: "unsaved-icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon",
      "size": "16"
    }
  })], 1), _c('div', {
    staticClass: "unsaved-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.form.unsaved')) + " ")])]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }