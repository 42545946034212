var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    staticClass: "tabs",
    attrs: {
      "vertical": ""
    },
    on: {
      "activate-tab": _vm.check
    }
  }, [_vm._l(_vm.tabs, function (tab) {
    return _c('b-tab', {
      key: tab.title,
      staticClass: "tab",
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('vs-form-tab', {
            attrs: {
              "title": tab.title,
              "icon": tab.icon
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('vs-form-tab-content', {
      attrs: {
        "title": tab.title,
        "icon": tab.icon
      }
    }, [_vm.isLoading ? _c('vs-loader', {
      attrs: {
        "text": "Trwa pobieranie danych inwestycji..."
      }
    }) : _vm._e(), tab.content && !_vm.isLoading ? _c(tab.content, {
      tag: "component",
      attrs: {
        "subject": _vm.subject,
        "feedback": _vm.feedback,
        "investment": _vm.investment,
        "title": " ",
        "offer": _vm.investmentCurrentOffer
      },
      on: {
        "add": function (item) {
          return _vm.subject.push(item);
        },
        "addVehicle": _vm.addVehicle,
        "editVehicle": _vm.editVehicle,
        "removeVehicle": _vm.removeVehicle
      },
      model: {
        value: _vm.fields,
        callback: function ($$v) {
          _vm.fields = $$v;
        },
        expression: "fields"
      }
    }) : _vm._e()], 1)], 1);
  }), _c('b-tab', {
    staticClass: "tab-button",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "tab-button-title"
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "primary"
          },
          on: {
            "click": _vm.updateInvestment
          }
        }, [_c('div', {
          staticClass: "tab-button-title-icon"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SaveIcon"
          }
        })], 1), _c('div', {
          staticClass: "tab-button-title-text"
        }, [_vm._v(" Zapisz inwestycję ")])])], 1)];
      },
      proxy: true
    }])
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }