var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-material-offer-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.investment.tab.offer.field"
    },
    model: {
      value: _vm.offer,
      callback: function ($$v) {
        _vm.offer = $$v;
      },
      expression: "offer"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "investment_requirement_group_id"
    }
  }, [_c('investments-material-picker', {
    attrs: {
      "reduce": function (material) {
        return material.id;
      }
    },
    model: {
      value: _vm.offer.investment_requirement_group_id,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "investment_requirement_group_id", $$v);
      },
      expression: "offer.investment_requirement_group_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "crm_provider_id",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-provider-picker', {
    model: {
      value: _vm.offer.crm_provider_id,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "crm_provider_id", $$v);
      },
      expression: "offer.crm_provider_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "valid_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.validDate,
      callback: function ($$v) {
        _vm.validDate = $$v;
      },
      expression: "validDate"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "guarantee_price_date"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "period-checkbox",
    model: {
      value: _vm.offer.whole_period,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "whole_period", $$v);
      },
      expression: "offer.whole_period"
    }
  }, [_vm._v(" Cały okres robót ")]), !_vm.offer.whole_period ? _c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.warrantyDate,
      callback: function ($$v) {
        _vm.warrantyDate = $$v;
      },
      expression: "warrantyDate"
    }
  }) : _vm._e()], 1), _c('vs-form-group', {
    attrs: {
      "field": "value"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "PLN"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.offer.field.value')
    },
    model: {
      value: _vm.offer.value,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "value", $$v);
      },
      expression: "offer.value"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }