<template>
  <div class="investments-subject-form">
    <vs-form
      i18n-namespace="investments.form.investment.field"
      no-padding
    >
      <vs-form-group
        field="work_groups"
      >
        <investments-group-picker
          multiple
          v-model="investmentForm.work_groups"
          :reduce="group => group.id"
        />
      </vs-form-group>

      <vs-form-group
        field="work_types"
      >
        <investments-work-type-picker
          multiple
          v-model="investmentForm.work_types"
          :reduce="type => type.id"
        />
      </vs-form-group>

    </vs-form>

    <vs-form-header with-button
      :text="$t('investments.form.investment.tab.subject.section.list')"
      :button-text="`+ ${$t('investments.form.investment.tab.subject.button.add')}`"
      @action="investmentAssignSubjectModal = true"
      buttonPermission="investment.subject.store"
    />

    <authorization-guard
      permission="investment.subject.index"
    >
      <vs-table
        class="subject-list"
        :columns="cols"
        :rows="sampleSubjects"
        :show-search="false"
        :show-pagination="false"
        :actionItems="actionItems"
        show-action-button
      >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'technology' && row.technology">
            {{ row.technology.name }}
          </span>

          <span v-if="column.field === 'diameter'">
            {{ Number(row.diameter).toLocaleString() }} mm
          </span>

          <span v-if="column.field === 'quantity'">
            {{ Number(row.quantity).toLocaleString() }}
          </span>

          <span v-if="column.field === 'length'">
            {{ Number(row.length).toLocaleString() }} m
          </span>

          <span v-if="column.field === 'mb'">
            {{ (row.length * row.quantity).toLocaleString() }} mb
          </span>

          <span v-else-if="column.field === 'reinforcement' && row.reinforcement">
            {{ row.reinforcement.name }}
          </span>
        </template>
      </vs-table>
    </authorization-guard>

    <investments-assign-subject-modal
      :investment-id="investment.id"
      @success="addSubject"
    />

    <investments-edit-subject-modal
      @success="updateSubject"
    />

  </div>
</template>

<script>
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { computed, ref } from 'vue';
import { deleteModal } from '@/libs/modals';
import { DeleteSubjectError, DeleteSubjectSuccess } from '@/libs/toasts/feedback/module/investments';

import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import InvestmentsGroupPicker from '@/components/module/investments/InvestmentsGroupPicker.vue';
import InvestmentsWorkTypePicker from '@/components/module/investments/InvestmentsWorkTypePicker.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import InvestmentsEditSubjectModal from '../modals/InvestmentsEditSubjectModal.vue';
import InvestmentsAssignSubjectModal from '../modals/InvestmentsAssignSubjectModal.vue';

export default {
  name: 'InvestmentsSubjectForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const investmentForm = computed(() => props.value);
    const { investmentAssignSubjectModal, investmentEditSubjectModal } = useInvestmentsStore();

    const sampleSubjects = ref(props.investment?.subjects);

    const tableI18n = 'investments.form.investment.tab.subject.table';
    const cols = [
      {
        label: i18n.t(`${tableI18n}.technology`),
        field: 'technology',
      },
      {
        label: i18n.t(`${tableI18n}.diameter`),
        field: 'diameter',
      },
      {
        label: i18n.t(`${tableI18n}.quantity`),
        field: 'quantity',
      },
      {
        label: i18n.t(`${tableI18n}.length`),
        field: 'length',
      },
      {
        label: i18n.t(`${tableI18n}.mb`),
        field: 'mb',
      },
      {
        label: i18n.t(`${tableI18n}.reinforcement`),
        field: 'reinforcement',
      },
    ];

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        requiresPermission: 'investment.subject.update',
        callback: (row) => {
          investmentEditSubjectModal.value.visible = true;
          investmentEditSubjectModal.value.data = { ...row };
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        requiresPermission: 'investment.subject.destroy',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.subject.delete.title'),
            text: i18n.t('investments.modal.subject.delete.text', {
              technology: obj.technology.name,
            }),
          });

          if (!confirmation) return false;

          useInvestmentsApi()
            .deleteSubject(obj.id)
            .then(() => {
              sampleSubjects.value = sampleSubjects.value
                .filter(({ id }) => id !== obj.id);

              showToast(DeleteSubjectSuccess);
            })
            .catch(() => showToast(DeleteSubjectError));

          return true;
        },
      },
    ];

    const addSubject = (subject) => {
      sampleSubjects.value.push({ ...subject });
    };

    const updateSubject = (subject) => {
      sampleSubjects.value = sampleSubjects.value.map((s) => {
        if (s.id !== subject.id) return s;
        return subject;
      });
    };

    return {
      sampleSubjects,
      cols,
      investmentForm,
      investmentAssignSubjectModal,
      actionItems,

      addSubject,
      updateSubject,
    };
  },
  components: {
    VsForm,
    VsFormGroup,
    InvestmentsGroupPicker,
    InvestmentsWorkTypePicker,
    VsTable,
    VsFormHeader,
    InvestmentsAssignSubjectModal,
    InvestmentsEditSubjectModal,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
.vs-form-header
  width: calc(100% + 15px)
  margin-left: -15px

.subject-list
  margin-top: 40px
</style>
