<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useInvestmentsReinforcementStore from '@/stores/module/investments/useInvestmentsReinforcementStore';

export default Vue.component('InvestmentsReinforcementPicker', {
  emits,
  setup(_, ctx) {
    const store = useInvestmentsReinforcementStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz zbrojenie z listy',
      reduce: (option) => option.id,
      options: store.state,
      ...ctx.attrs,
    }));
  },
  components: { VsPickerVue },
});
</script>
