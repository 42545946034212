<template>
  <b-tabs vertical class="tabs" @activate-tab="check">
    <b-tab
      v-for="tab in tabs"
      :key="tab.title"
      class="tab"
    >
      <template #title>
        <vs-form-tab
          :title="tab.title"
          :icon="tab.icon"
        />
      </template>

      <vs-form-tab-content
        :title="tab.title"
        :icon="tab.icon"
      >
        <vs-loader
          text="Trwa pobieranie danych inwestycji..."
          v-if="isLoading"
        />

        <component
          v-if="tab.content && !isLoading"
          :is="tab.content"
          :subject="subject"
          @add="item => subject.push(item)"
          v-model="fields"
          :feedback="feedback"
          :investment="investment"
          title=" "
          :offer="investmentCurrentOffer"
          @addVehicle="addVehicle"
          @editVehicle="editVehicle"
          @removeVehicle="removeVehicle"
        />
      </vs-form-tab-content>
    </b-tab>

    <b-tab class="tab-button">
      <template #title>
        <div class="tab-button-title">

          <vs-button
            vs-variant="primary"
            @click="updateInvestment"
            >
            <div class="tab-button-title-icon">
              <feather-icon icon="SaveIcon" />
            </div>
            <div class="tab-button-title-text">
              Zapisz inwestycję
            </div>
          </vs-button>

        </div>
      </template>
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import InvestmentsEditForm from '@/components/views/investments/forms/InvestmentsEditForm.vue';
import InvestmentsSubjectForm from '@/components/views/investments/forms/InvestmentsSubjectForm.vue';
import InvestmentsDemandForm from '@/components/views/investments/forms/InvestmentsDemandForm.vue';
import InvestmentsResourceForm from '@/components/views/investments/forms/InvestmentsResourceForm.vue';
import InvestmentsCadreForm from '@/components/views/investments/forms/InvestmentsCadreForm.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import { computed, provide, ref } from 'vue';
import VsFormTab from '@/components/ui/vs-form/VsFormTab.vue';
import VsFormTabContent from '@/components/ui/vs-form/VsFormTabContent.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { useRouter } from '@/@core/utils/utils';
import showToast from '@/libs/toasts';
import {
  FetchInvestmentError,
  UpdateInvestmentSuccess,
  UpdateInvestmentError,
  UploadInvestmentOfferError,
  AttachmentSizeError,
} from '@feedback/module/investments';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  name: 'InvestmentsSettings',
  setup() {
    const { route, router } = useRouter();
    const isLoading = ref(false);

    const investmentId = computed(() => route.value.params?.id);
    const investment = ref({});

    const investmentForm = useValidatedForm({
      name: 'required',
      shortcut: 'required',
      core_branch_id: 'required',
      serial: 'required',
      external_contract: '',
      investment_status_id: 'required',
      street: '',
      post_code: '',
      city: '',
      plot_number: '',
      latitude: 'latitude',
      longitude: 'longitude',
      address_description: '',
      crm_investor_id: '',
      crm_principal_id: '',
      work_groups: '',
      work_types: '',
      estimated_scheduler: {
        start_date: 'required',
        end_date: 'required',
        documentation_date: 'required',
      },
      contract_scheduler: {
        start_date: 'required',
        end_date: 'required',
        documentation_date: 'required',
      },
    }, 'investments.form.investment.field');

    const fetchInvestment = (fetchingId) => {
      if (!fetchingId) return;
      isLoading.value = true;

      useInvestmentsApi()
        .fetchInvestment(fetchingId)
        .then(({ data }) => {
          investment.value = {
            ...data.data,
          };
          investmentForm.assignFields({
            ...data.data,
            work_groups: data.data.work_groups.map(({ id }) => id),
            work_types: data.data.work_types.map(({ id }) => id),
          });
          isLoading.value = false;
        })
        .catch(() => {
          showToast(FetchInvestmentError);

          router.push({ name: 'investments' });
          isLoading.value = false;
        });
    };

    const uploadInvestmentOffer = (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('mediable_id', investmentId.value);
      formData.append('type', 'offer');

      useInvestmentsApi()
        .uploadAttachment(investmentId.value, formData)
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(UploadInvestmentOfferError);
          }
        });
    };

    const updateInvestment = () => {
      if (investmentForm.fields.value.offer) {
        uploadInvestmentOffer(investmentForm.fields.value.offer);
      }

      useInvestmentsApi()
        .updateInvestment(investmentId.value, {
          ...investmentForm.nonEmptyFields.value,
        })
        .then(({ data }) => {
          investment.value = { ...data.data };
          investmentForm.clearErrors();
          RemoteResourceManager.getInstance().notify('investments.investment');
          showToast(UpdateInvestmentSuccess, { name: data.data.name });
        })
        .catch(({ response }) => {
          investmentForm.assignServerSideErrors(response?.data?.errors);
          showToast(UpdateInvestmentError);
        });
    };

    fetchInvestment(route.value.params?.id);
    provide('fetchInvestment', () => fetchInvestment(route.value.params?.id));

    const tabs = [
      { title: 'Kartoteka inwestycji', icon: 'FolderIcon', content: InvestmentsEditForm },
      { title: 'Przedmiot zamówienia', icon: 'ListIcon', content: InvestmentsSubjectForm },
      { title: 'Zapotrzebowanie', icon: 'PackageIcon', content: InvestmentsDemandForm },
      { title: 'Maszyny i sprzęt', icon: 'TruckIcon', content: InvestmentsResourceForm },
      { title: 'Ludzie', icon: 'UsersIcon', content: InvestmentsCadreForm },
    ];

    const subject = ref([]);

    // disable tabs not specified above
    const check = (newTab, _prevTab, evt) => {
      if (newTab >= tabs.length) {
        evt.preventDefault();
      }
    };

    const investmentCurrentOffer = computed(() => [...investment.value?.attachments ?? []]
      .reverse()
      .find((attachment) => attachment.type === 'offer'));

    const addVehicle = (vehicle) => {
      investment.value.base_resource.push(vehicle);
    };

    const editVehicle = (vehicle) => {
      investment.value.base_resource = investment.value.base_resource
        .map((v) => (v.id === vehicle.id ? vehicle : v));
    };

    const removeVehicle = (vehicleId) => {
      investment.value.base_resource = investment.value.base_resource
        .filter((v) => v.id !== vehicleId);
    };

    return {
      tabs,
      check,
      subject,
      isLoading,
      fields: investmentForm.fields,
      feedback: investmentForm.feedback,
      updateInvestment,
      investment,
      investmentCurrentOffer,
      addVehicle,
      editVehicle,
      removeVehicle,
    };
  },
  components: {
    BCard,
    BTabs,
    BTab,
    VsButton,
    VsFormTab,
    VsFormTabContent,
    VsLoader,
  },
};
</script>

<style lang="sass" scoped>
.tab-button-title
  padding: 55px 0

  &-text
    @media screen and (max-width: 1500px)
      display: none

.tabs::v-deep
  padding: 30px 0
  position: relative
  display: grid
  grid-template-columns: min-content 1fr

  .tab-content
    .card-body
      max-width: 100% !important
      overflow-x: auto

  .col-auto
    position: sticky !important
    top: 100px
    height: 500px
    overflow: hidden

    @media screen and (max-width: 768px)
      position: relative !important
      top: 0
      height: auto

  &:before
    $line: rgba(128, 128, 128, 0.2)
    $line-alt: rgba($line, 0)

    content: ''
    width: 2px
    height: 100%
    position: absolute
    top: 0
    left: 41px
    background: linear-gradient(180deg, $line-alt 0%, $line 20%, $line 80%, $line-alt 100%)
    z-index: 0

  .nav-link
    justify-content: flex-start !important

    &:after
      background: none !important
</style>
