var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-subject-form"
  }, [_c('vs-form', {
    attrs: {
      "i18n-namespace": "investments.form.investment.field",
      "no-padding": ""
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "work_groups"
    }
  }, [_c('investments-group-picker', {
    attrs: {
      "multiple": "",
      "reduce": function (group) {
        return group.id;
      }
    },
    model: {
      value: _vm.investmentForm.work_groups,
      callback: function ($$v) {
        _vm.$set(_vm.investmentForm, "work_groups", $$v);
      },
      expression: "investmentForm.work_groups"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "work_types"
    }
  }, [_c('investments-work-type-picker', {
    attrs: {
      "multiple": "",
      "reduce": function (type) {
        return type.id;
      }
    },
    model: {
      value: _vm.investmentForm.work_types,
      callback: function ($$v) {
        _vm.$set(_vm.investmentForm, "work_types", $$v);
      },
      expression: "investmentForm.work_types"
    }
  })], 1)], 1), _c('vs-form-header', {
    attrs: {
      "with-button": "",
      "text": _vm.$t('investments.form.investment.tab.subject.section.list'),
      "button-text": `+ ${_vm.$t('investments.form.investment.tab.subject.button.add')}`,
      "buttonPermission": "investment.subject.store"
    },
    on: {
      "action": function ($event) {
        _vm.investmentAssignSubjectModal = true;
      }
    }
  }), _c('authorization-guard', {
    attrs: {
      "permission": "investment.subject.index"
    }
  }, [_c('vs-table', {
    staticClass: "subject-list",
    attrs: {
      "columns": _vm.cols,
      "rows": _vm.sampleSubjects,
      "show-search": false,
      "show-pagination": false,
      "actionItems": _vm.actionItems,
      "show-action-button": ""
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'technology' && row.technology ? _c('span', [_vm._v(" " + _vm._s(row.technology.name) + " ")]) : _vm._e(), column.field === 'diameter' ? _c('span', [_vm._v(" " + _vm._s(Number(row.diameter).toLocaleString()) + " mm ")]) : _vm._e(), column.field === 'quantity' ? _c('span', [_vm._v(" " + _vm._s(Number(row.quantity).toLocaleString()) + " ")]) : _vm._e(), column.field === 'length' ? _c('span', [_vm._v(" " + _vm._s(Number(row.length).toLocaleString()) + " m ")]) : _vm._e(), column.field === 'mb' ? _c('span', [_vm._v(" " + _vm._s((row.length * row.quantity).toLocaleString()) + " mb ")]) : column.field === 'reinforcement' && row.reinforcement ? _c('span', [_vm._v(" " + _vm._s(row.reinforcement.name) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c('investments-assign-subject-modal', {
    attrs: {
      "investment-id": _vm.investment.id
    },
    on: {
      "success": _vm.addSubject
    }
  }), _c('investments-edit-subject-modal', {
    on: {
      "success": _vm.updateSubject
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }