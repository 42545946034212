var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "tab-content"
  }, [_c('div', {
    staticClass: "tab-content-header"
  }, [_c('div', {
    staticClass: "tab-content-header-icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.icon,
      "size": "18"
    }
  })], 1), _c('div', {
    staticClass: "tab-content-header-text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]), _c('div', {
    staticClass: "tab-content-form"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }