var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-investment-edit-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18n-namespace": "investments.form.investment.field",
      "no-padding": ""
    },
    model: {
      value: _vm.investment,
      callback: function ($$v) {
        _vm.investment = $$v;
      },
      expression: "investment"
    }
  }, [_c('vs-form-header', {
    attrs: {
      "text": _vm.$t('investments.form.investment.tab.file.section.details')
    }
  }), _c('vs-form-group', {
    attrs: {
      "field": "name",
      "cols": "9",
      "labelCols": "4"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.name')
    },
    model: {
      value: _vm.investment.name,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "name", $$v);
      },
      expression: "investment.name"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "shortcut",
      "noLabel": "",
      "cols": "3"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.shortcut')
    },
    model: {
      value: _vm.investment.shortcut,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "shortcut", $$v);
      },
      expression: "investment.shortcut"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "core_branch_id"
    }
  }, [_c('core-branch-picker', {
    model: {
      value: _vm.investment.core_branch_id,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "core_branch_id", $$v);
      },
      expression: "investment.core_branch_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "crm_investor_id",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-client-picker', {
    attrs: {
      "reduce": function (client) {
        return client.id;
      }
    },
    model: {
      value: _vm.investment.crm_investor_id,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "crm_investor_id", $$v);
      },
      expression: "investment.crm_investor_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "crm_principal_id",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-client-picker', {
    attrs: {
      "reduce": function (client) {
        return client.id;
      }
    },
    model: {
      value: _vm.investment.crm_principal_id,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "crm_principal_id", $$v);
      },
      expression: "investment.crm_principal_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "external_contract"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.external_contract')
    },
    model: {
      value: _vm.investment.external_contract,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "external_contract", $$v);
      },
      expression: "investment.external_contract"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "serial"
    }
  }, [_c('div', {
    staticClass: "editable-spans"
  }, [_c('span', {
    attrs: {
      "contenteditable": "",
      "spellcheck": "false"
    },
    on: {
      "blur": function (e) {
        return _vm.updateSerialKey(e, 'id');
      }
    }
  }, [_vm._v(_vm._s(_vm.serial.id))]), _c('span', [_vm._v("/" + _vm._s(_vm.serial.branch || 'X') + "/")]), _c('span', {
    attrs: {
      "contenteditable": "",
      "spellcheck": "false"
    },
    on: {
      "blur": function (e) {
        return _vm.updateSerialKey(e, 'year');
      }
    }
  }, [_vm._v(_vm._s(_vm.serial.year))]), _c('span', [_vm._v("/" + _vm._s(_vm.serial.shortcut || 'X') + "/")]), _c('span', {
    attrs: {
      "contenteditable": "",
      "spellcheck": "false"
    },
    on: {
      "blur": function (e) {
        return _vm.updateSerialKey(e, 'provider');
      }
    }
  }, [_vm._v(_vm._s(_vm.serial.provider))])])]), _c('vs-form-group', {
    attrs: {
      "field": "offer"
    }
  }, [_vm.offer ? _c('div', {
    staticClass: "info"
  }, [_vm._v(" Aktualna oferta ("), _c('a', {
    attrs: {
      "href": _vm.offer.file_link
    }
  }, [_vm._v(_vm._s(_vm.offer.media.original_name))]), _vm._v(") została przesłana w dniu " + _vm._s(_vm.formatDate.training(_vm.offer.media.created_at)) + ". Przesłanie nowszego pliku nie spowoduje usunięcia obecnej oferty. "), !_vm.replacingOffer ? _c('div', {
    staticClass: "info-upload-new"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        _vm.replacingOffer = true;
      }
    }
  }, [_vm._v(" Zaktualizuj ofertę ")])], 1) : _vm._e()]) : _vm._e(), !_vm.offer || _vm.replacingOffer ? _c('vs-file', {
    staticClass: "file-upload",
    attrs: {
      "placeholder": "Wybierz aktualną ofertę"
    },
    model: {
      value: _vm.investment.offer,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "offer", $$v);
      },
      expression: "investment.offer"
    }
  }) : _vm._e()], 1), _c('vs-form-group', {
    attrs: {
      "field": "investment_status_id"
    }
  }, [_c('investments-status-picker', {
    model: {
      value: _vm.investment.investment_status_id,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "investment_status_id", $$v);
      },
      expression: "investment.investment_status_id"
    }
  })], 1), _c('vs-form-header', {
    attrs: {
      "text": _vm.$t('investments.form.investment.tab.file.section.location')
    }
  }), _c('vs-form-group', {
    attrs: {
      "field": "street",
      "labelCols": "12",
      "cols": "8"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.street')
    },
    model: {
      value: _vm.investment.street,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "street", $$v);
      },
      expression: "investment.street"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "post_code",
      "labelCols": "12",
      "cols": "4"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "82-300"
    },
    model: {
      value: _vm.investment.post_code,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "post_code", $$v);
      },
      expression: "investment.post_code"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "city",
      "labelCols": "12",
      "cols": "8"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.city')
    },
    model: {
      value: _vm.investment.city,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "city", $$v);
      },
      expression: "investment.city"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "plot_number",
      "labelCols": "12",
      "cols": "4"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.plot_number')
    },
    model: {
      value: _vm.investment.plot_number,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "plot_number", $$v);
      },
      expression: "investment.plot_number"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "latitude",
      "labelCols": "12",
      "cols": "6"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "54.139691429860456"
    },
    model: {
      value: _vm.investment.latitude,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "latitude", $$v);
      },
      expression: "investment.latitude"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "longitude",
      "labelCols": "12",
      "cols": "6"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "19.44957203068522"
    },
    model: {
      value: _vm.investment.longitude,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "longitude", $$v);
      },
      expression: "investment.longitude"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "address_description",
      "labelCols": "12"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.investment.address_description,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "address_description", $$v);
      },
      expression: "investment.address_description"
    }
  })], 1), _c('div', {
    staticClass: "split"
  }, [_c('div', {
    staticClass: "split-part"
  }, [_c('vs-form-header', {
    attrs: {
      "text": _vm.$t('investments.form.investment.tab.file.section.contractDeadline'),
      "preserveMargin": ""
    }
  }), _c('vs-form-group', {
    attrs: {
      "field": "contract_scheduler.start_date",
      "labelCols": "12"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.investment.contract_scheduler.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.investment.contract_scheduler, "start_date", $$v);
      },
      expression: "investment.contract_scheduler.start_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "contract_scheduler.end_date",
      "labelCols": "12"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.investment.contract_scheduler.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.investment.contract_scheduler, "end_date", $$v);
      },
      expression: "investment.contract_scheduler.end_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "contract_scheduler.documentation_date",
      "labelCols": "12"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.investment.contract_scheduler.documentation_date,
      callback: function ($$v) {
        _vm.$set(_vm.investment.contract_scheduler, "documentation_date", $$v);
      },
      expression: "investment.contract_scheduler.documentation_date"
    }
  })], 1)], 1), _c('div', {
    staticClass: "split-part"
  }, [_c('vs-form-header', {
    attrs: {
      "text": _vm.$t('investments.form.investment.tab.file.section.estimateDeadline'),
      "preserveMargin": ""
    }
  }), _c('vs-form-group', {
    attrs: {
      "field": "estimated_scheduler.start_date",
      "labelCols": "12"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.investment.estimated_scheduler.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.investment.estimated_scheduler, "start_date", $$v);
      },
      expression: "investment.estimated_scheduler.start_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "estimated_scheduler.end_date",
      "labelCols": "12"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.investment.estimated_scheduler.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.investment.estimated_scheduler, "end_date", $$v);
      },
      expression: "investment.estimated_scheduler.end_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "estimated_scheduler.documentation_date",
      "labelCols": "12"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.investment.estimated_scheduler.documentation_date,
      callback: function ($$v) {
        _vm.$set(_vm.investment.estimated_scheduler, "documentation_date", $$v);
      },
      expression: "investment.estimated_scheduler.documentation_date"
    }
  })], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }