var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-assign-subject-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.investment.tab.subject.field"
    },
    model: {
      value: _vm.subject,
      callback: function ($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "base_technology_id",
      "integration": "base_technology_relation"
    }
  }, [_c('base-technology-picker', {
    model: {
      value: _vm.subject.base_technology_id,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "base_technology_id", $$v);
      },
      expression: "subject.base_technology_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "diameter"
    }
  }, [_c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('b-input-group-text', [_vm._v("mm")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.subject.field.diameter')
    },
    model: {
      value: _vm.subject.diameter,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "diameter", $$v);
      },
      expression: "subject.diameter"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "length",
      "cols": "9",
      "labelCols": "4"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "m"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.subject.field.length')
    },
    model: {
      value: _vm.subject.length,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "length", $$v);
      },
      expression: "subject.length"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "quantity",
      "cols": "3",
      "noLabel": ""
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.subject.field.quantity')
    },
    model: {
      value: _vm.subject.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "quantity", $$v);
      },
      expression: "subject.quantity"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "investment_subject_reinforcement_id"
    }
  }, [_c('investments-reinforcement-picker', {
    attrs: {
      "reduce": function (_ref) {
        var id = _ref.id;
        return id;
      }
    },
    model: {
      value: _vm.subject.investment_subject_reinforcement_id,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "investment_subject_reinforcement_id", $$v);
      },
      expression: "subject.investment_subject_reinforcement_id"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }