<template>
  <div class="investments-brigade-form">
    <vs-form
      v-model="brigade"
      :errors="errors"
      i18nNamespace="investments.form.investment.tab.brigade.field"
    >

      <vs-form-group
        field="name"
        >
        <b-form-input
          :placeholder="$t('investments.form.investment.tab.brigade.field.name')"
          v-model="brigade.name"
        />

        <b-form-checkbox
          v-model="brigade.is_management"
          class="management-checkbox"
          >
          Oznacz brygadę jako kierowniczą
        </b-form-checkbox>

      </vs-form-group>

      <vs-form-group
        field="investment_base_vehicles"
        integration="base_resource_investment_relation"
        info="Maszyna musi zostać najpierw dodana do inwestycji w jej ustawieniach"
        >

        <b-form-checkbox
          v-model="hasVehicle"
          class="machine-checkbox"
          >
          Przypisz brygadę do maszyny
        </b-form-checkbox>

        <div
          class="vehicles"
          v-if="hasVehicle"
        >
          <div class="vehicle">
            <label class="vehicle-label">Maszyna główna</label>
            <base-resource-picker
              v-model="brigade.main_vehicle"
              :excluded="shouldResourceBeExcluded"
            />
          </div>

          <div class="vehicle">
            <label class="vehicle-label">Zasoby dodatkowe</label>
            <base-resource-picker
              multiple
              v-model="brigade.additional_vehicles"
              :excluded="shouldExtraResourceBeExcluded"
            />
          </div>
        </div>
      </vs-form-group>
    </vs-form>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue';
import {
  computed, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';

export default {
  name: 'InvestmentsBrigadeForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    investmentVehicleIds: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const brigade = computed(() => props.value);
    const errors = ref({});
    const checkboxValue = ref(false);

    const hasVehicle = computed({
      get() {
        return checkboxValue.value
          || props.value.main_vehicle !== null
          || props.value?.additional_vehicles?.length;
      },
      set(value) {
        if (!value) {
          brigade.value.main_vehicle = null;
          brigade.value.additional_vehicles = [];
        }
        checkboxValue.value = value;
      },
    });

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    const shouldResourceBeExcluded = (resource) => {
      if (resource.type?.slug !== 'vehicle') return true;
      if (!props.investmentVehicleIds.includes(resource.id)) return true;
      return false;
    };

    const shouldExtraResourceBeExcluded = (resource) => {
      if (!['vehicle', 'tool'].includes(resource.type?.slug)) return true;
      if (!props.investmentVehicleIds.includes(resource.id)) return true;
      return false;
    };

    return {
      brigade,
      errors,
      hasVehicle,
      shouldResourceBeExcluded,
      shouldExtraResourceBeExcluded,
    };
  },
  components: {
    BFormInput,
    BFormCheckbox,
    VsForm,
    VsFormGroup,
    BaseResourcePicker,
  },
};
</script>

<style lang="sass" scoped>
.vehicles
  display: flex
  flex-direction: column
  gap: 10px

  .vehicle
    &-label
      margin-bottom: 5px
      font-size: 10pt

.management-checkbox
  margin: 7px 0
.machine-checkbox
  margin: 7px 0
</style>
