<template>
  <b-card class="tab-content">

    <div class="tab-content-header">
      <div class="tab-content-header-icon">
        <feather-icon :icon="icon" size="18" />
      </div>

      <div class="tab-content-header-text">
        {{ title }}
      </div>
    </div>

    <div class="tab-content-form">
      <slot></slot>
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

export default {
  name: 'VsFormTabContent',
  props: {
    title: String,
    icon: String,
  },
  components: {
    BCard,
  },
};
</script>

<style lang="sass" scoped>
.tab-content
  width: 90%
  margin: 0 auto

  @media screen and (max-width: 768px)
    width: 100%

  &-form
    margin-top: 30px
    padding: 25px 65px

    @media screen and (max-width: 768px)
      padding: 25px 0

  &-header
    display: flex
    align-items: center
    font-size: 12pt
    font-weight: 500
    padding: 10px

    &-icon
      margin-right: 15px
      color: rgba(128, 128, 128, 0.8)
      border: 2px solid rgba(128, 128, 128, 0.2)
      width: 40px
      aspect-ratio: 1/1
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
</style>
