<template>
  <div class="investments-edit-offer-modal">
    <b-modal
      id="modal-investments-edit-offer"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.offer.edit.title')"

      v-model="investmentEditOfferModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.offer.edit.loading')"
        v-if="isLoading"
      />

      <investments-material-offer-form
        v-model="fields"
        :feedback="feedback"
      />

      <div class="attachments-wrapper">
        <attachments useProps
          additionalInfo="Dokonane zmiany w załącznikach mają
            skutek natychmiastowy, bez potrzeby zapisywania oferty"
          :items="files"
          @add="addAttachment"
          @remove="removeAttachment"
        />
      </div>

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.offer.edit.cancel')"
          :text-ok="$t('investments.modal.offer.edit.confirm')"

          @ok="editOffer"
          @cancel="investmentEditOfferModal.visible = false"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { EditOfferError, EditOfferSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import formatDate from '@/libs/date-formatter';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import InvestmentsMaterialOfferForm from '../forms/InvestmentsMaterialOfferForm.vue';

export default {
  name: 'InvestmentsEditOfferModal',
  props: {},
  setup(_props, { emit }) {
    const { investmentEditOfferModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const offer = ref(null);

    const {
      fields, feedback, validateAllFields, resetFields, validateField,
      assignFields, nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      id: 'required|integer',
      investment_requirement_group_id: 'required|integer',
      crm_provider_id: 'required|integer',
      provider: '',
      attachments: '',
      valid_date: 'required',
      guarantee_price_date: 'required_or:@whole_period',
      whole_period: 'required_or:@guarantee_price_date',
      value: 'required|integer_or_double|positive',
      accepted: '',
      accepted_by: '',
      accepted_date: '',
    }, 'investments.form.investment.tab.offer.field');

    watch(() => fields.value.whole_period, () => validateField('guarantee_price_date'));

    const files = ref([]);
    watch(() => files.value, () => {
      emit('success', {
        ...offer.value,
        files: files.value.map((f) => ({
          media: f,
          link: f.file_link,
        })),
      });
    });

    watch(() => investmentEditOfferModal.value.visible, () => {
      const { data } = investmentEditOfferModal.value;
      if (data && Object.keys(data).length === 0) return;

      files.value = [...investmentEditOfferModal.value.data?.files
        ?.map((f) => ({ ...f.media, file_link: f.link })) ?? []];

      const editingValues = {
        ...investmentEditOfferModal.value.data,
      };

      offer.value = {
        ...investmentEditOfferModal.value.data,
      };

      assignFields(editingValues);
      if (!fields.value.guarantee_price_date) {
        fields.value.whole_period = true;
      }

      investmentEditOfferModal.value.data = {};
    });

    const editOffer = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;
      if (!offer.value.id) return;

      isLoading.value = true;

      if (!fields.value.whole_period) {
        fields.value.guarantee_price_date = formatDate.standard(fields.value.guarantee_price_date);
      }

      useInvestmentsApi()
        .updateOffer(offer.value.id, {
          ...nonEmptyFields.value,
          investment_id: offer.value.investment_id,
          value: `${fields.value.value}`,
          valid_date: formatDate.standard(fields.value.valid_date),
        })
        .then(({ data }) => {
          showToast(EditOfferSuccess);
          emit('success', data.data);

          resetFields();
          investmentEditOfferModal.value.visible = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(EditOfferError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    const addAttachment = (attachment) => {
      const formData = new FormData();

      formData.append('type', 'offer');
      formData.append('file', attachment.file);
      formData.append('mediable_id', fields.value.id);

      useInvestmentsApi()
        .addOfferAttachment(formData)
        .then(({ data }) => {
          files.value.push({
            ...data.data.file,
          });
        });
    };

    const removeAttachment = (attachment) => {
      useInvestmentsApi()
        .deleteOfferAttachment(attachment)
        .then(() => {
          files.value = files.value.filter((f) => f.id !== attachment);
        });
    };

    return {
      fields,
      feedback,
      investmentEditOfferModal,
      isLoading,
      editOffer,
      validateField,
      addAttachment,
      removeAttachment,
      files,
    };
  },
  components: {
    VsLoader,
    InvestmentsMaterialOfferForm,
    VsModalFooter,
    Attachments,
  },
};
</script>

<style lang="sass" scoped>
.attachments-wrapper
  padding: 10px 40px

.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between

  .buttons
    margin-left: auto
</style>
