<template>
  <div class="investments-assign-subject-modal">
    <b-modal
      id="modal-investments-assign-subject"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.subject.create.title')"

      v-model="investmentAssignSubjectModal"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.subject.create.loading')"
        v-if="isLoading"
      />

      <investments-assign-subject-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.subject.create.cancel')"
          :text-ok="$t('investments.modal.subject.create.confirm')"

          @ok="assignSubject"
          @cancel="investmentAssignSubjectModal = false"
        >
          <template #left>
            <b-form-checkbox v-model="continueEditing" name="check-button">
              Dodaj kolejny przedmiot zamówienia
            </b-form-checkbox>
          </template>
        </vs-modal-footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import showToast from '@/libs/toasts';
import { AssignSubjectError, AssignSubjectSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsAssignSubjectForm from '../forms/InvestmentsAssignSubjectForm.vue';

export default {
  name: 'InvestmentsAssignSubjectModal',
  props: ['investmentId'],
  setup(props, { emit }) {
    const { investmentAssignSubjectModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields, nonEmptyFields,
      assignServerSideErrors,
    } = useValidatedForm({
      base_technology_id: 'required|integer',
      diameter: 'required|integer_or_double|positive',
      length: 'required|integer_or_double|positive',
      quantity: 'required|integer|positive',
      investment_subject_reinforcement_id: 'required|integer',
    }, 'investments.form.investment.tab.subject.field');

    const continueEditing = ref(false);

    const assignSubject = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      isLoading.value = true;

      useInvestmentsApi()
        .assignSubject({
          ...nonEmptyFields.value,
          investment_id: props.investmentId,
        })
        .then(({ data }) => {
          showToast(AssignSubjectSuccess);
          emit('success', data.data);

          if (!continueEditing.value) {
            investmentAssignSubjectModal.value = false;
          }

          resetFields();
          isLoading.value = false;
        })
        .catch(({ response }) => {
          assignServerSideErrors(response?.data?.errors);
          showToast(AssignSubjectError);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentAssignSubjectModal,
      isLoading,
      continueEditing,
      assignSubject,
    };
  },
  components: {
    VsLoader,
    BFormCheckbox,
    InvestmentsAssignSubjectForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
