var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-edit-offer-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-investments-edit-offer",
      "cancel-variant": "outline-secondary",
      "size": "lg",
      "title": _vm.$t('investments.modal.offer.edit.title'),
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        $event.preventDefault();
        return function () {}.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('vs-modal-footer', {
          attrs: {
            "is-loading": _vm.isLoading,
            "text-cancel": _vm.$t('investments.modal.offer.edit.cancel'),
            "text-ok": _vm.$t('investments.modal.offer.edit.confirm')
          },
          on: {
            "ok": _vm.editOffer,
            "cancel": function ($event) {
              _vm.investmentEditOfferModal.visible = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.investmentEditOfferModal.visible,
      callback: function ($$v) {
        _vm.$set(_vm.investmentEditOfferModal, "visible", $$v);
      },
      expression: "investmentEditOfferModal.visible"
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": _vm.$t('investments.modal.offer.edit.loading')
    }
  }) : _vm._e(), _c('investments-material-offer-form', {
    attrs: {
      "feedback": _vm.feedback
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  }), _c('div', {
    staticClass: "attachments-wrapper"
  }, [_c('attachments', {
    attrs: {
      "useProps": "",
      "additionalInfo": "Dokonane zmiany w załącznikach mają\n          skutek natychmiastowy, bez potrzeby zapisywania oferty",
      "items": _vm.files
    },
    on: {
      "add": _vm.addAttachment,
      "remove": _vm.removeAttachment
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }