<template>
  <div class="investments-material-demand-form">
    <vs-form
      v-model="subject"
      :errors="errors"
      i18nNamespace="investments.form.investment.tab.demand.field"
    >

      <vs-form-group
        field="investment_requirement_group_id"
        >
        <investments-material-picker
          v-model="subject.investment_requirement_group_id"
          :reduce="m => m.id"
          @pick="updateMaterialUnit"
        />
      </vs-form-group>

      <vs-form-group
        field="name"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.tab.demand.field.name')"
            v-model="subject.name"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="price"
        >
        <b-input-group append="PLN">
          <b-form-input
            :placeholder="$t('investments.form.investment.tab.demand.field.price')"
            v-model="subject.price"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="value"
        >
        <b-input-group
          :append="materialUnitCode"
        >
          <b-form-input
            :placeholder="$t('investments.form.investment.tab.demand.field.value')"
            v-model="subject.value"
          />
        </b-input-group>
      </vs-form-group>
    </vs-form>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import {
  computed, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import store from '@/store';
import InvestmentsMaterialPicker from '@/components/module/investments/InvestmentsMaterialPicker.vue';

export default {
  name: 'InvestmentsMaterialDemandForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const subject = computed(() => props.value);
    const errors = ref({});

    const materialUnit = ref({});
    const materialUnitCode = computed(() => materialUnit.value.code ?? '');

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    store.dispatch('units/fetch');
    const updateMaterialUnit = (material) => {
      materialUnit.value = store.getters['units/byId'](material.core_unit_id);
    };

    return {
      subject,
      errors,

      updateMaterialUnit,
      materialUnitCode,
    };
  },
  components: {
    BInputGroup,
    BFormInput,
    VsForm,
    VsFormGroup,
    InvestmentsMaterialPicker,
  },
};
</script>

<style lang="sass" scoped>
.editable-spans
  display: flex
  margin-top: 5px
  font-size: 12pt

  input
    width: 1em

  span
    display: block
    position: relative
    opacity: 0.7
    min-width: 10px

    &:empty
      min-width: 50px
      background: rgba(128, 128, 128, 0.1)
      border-radius: 5px

    &[contenteditable="true"]
      opacity: 1
      font-weight: 400

      &:focus
        outline: none
        font-weight: 500

      &:not(:empty):after
        left: 0
        display: block
        content: ''
        position: absolute
        background: orange
        width: 100%
        height: 1px

.serial-persistent
  text-align: center
  width: 10px
</style>
