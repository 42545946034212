<template>
  <div class="investments-cadre-form">

    <b-button
      variant="link"
      class="resource-button resource-button-brigade"
      @click="investmentCreateBrigadeModal.visible = true"
      v-can:store="'investment.brigade'"
      >
      + Dodaj brygadę
    </b-button>

    <b-button
      variant="link"
      class="resource-button resource-button-cadre"
      @click="investmentAssignEmployeeModal.visible = true"
      v-can:assign_employee="'investment.brigade'"
      >
      + Dodaj pracownika
    </b-button>

    <authorization-guard
      permission="investment.brigade.index"
    >
      <investments-cadre-details-list
        :brigades="populatedBrigades"
        @delete="deleteEmployee"
        @brigadeDelete="deleteBrigade"
      />
    </authorization-guard>

    <investments-assign-employee-modal
      @success="addEmployee"
      :employeeSelectable="employeeSelectable"
      :investment-id="investment.id"
    />

    <investments-edit-employee-modal
      @success="editEmployee"
      :investment-id="investment.id"
    />

    <investments-create-brigade-modal
      :investment="investment"
      @success="addBrigade"
      :investmentVehicleIds="investmentVehicleIds"
    />

    <investments-edit-brigade-modal
      :investment="investment"
      @success="editBrigade"
      :investmentVehicleIds="investmentVehicleIds"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { computed, ref } from 'vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import InvestmentsAssignEmployeeModal from '../modals/InvestmentsAssignEmployeeModal.vue';
import InvestmentsCreateBrigadeModal from '../modals/InvestmentsCreateBrigadeModal.vue';
import InvestmentsEditEmployeeModal from '../modals/InvestmentsEditEmployeeModal.vue';
import InvestmentsCadreDetailsList from '../ui/InvestmentsCadreDetailsList.vue';
import InvestmentsEditBrigadeModal from '../modals/InvestmentsEditBrigadeModal.vue';

export default {
  name: 'InvestmentsCadreForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const brigades = ref(props.investment?.brigades ?? []);

    const populatedBrigades = computed(() => brigades.value.map((brigade) => {
      const vehicleId = brigade.investment_base_vehicle_id;
      const vehicle = props.investment.base_vehicles
        ?.find((v) => v.id === vehicleId);

      return {
        ...brigade,
        linkedVehicle: {
          id: vehicle?.base_vehicle_id,
          name: vehicle?.base_vehicle?.resource?.name ?? 'Maszyna',
        },
      };
    }));

    const {
      investmentAssignEmployeeModal,
      investmentCreateBrigadeModal,
    } = useInvestmentsStore();

    const addEmployee = (employee) => {
      brigades.value = brigades.value.map((brigade) => {
        if (brigade.id !== employee.investment_brigade_id) return brigade;
        return {
          ...brigade,
          employees: [
            ...brigade.employees,
            employee,
          ],
        };
      });
    };

    const editEmployee = (employee) => {
      brigades.value = brigades.value.map((brigade) => {
        if (brigade.id !== employee.investment_brigade_id) return brigade;
        return {
          ...brigade,
          employees: brigade.employees
            .map((oldEmployee) => (oldEmployee.id === employee.id ? employee : oldEmployee)),
        };
      });
    };

    const deleteEmployee = (employee) => {
      brigades.value = brigades.value
        .map((brigade) => {
          if (brigade.id !== employee.investment_brigade_id) return brigade;
          return {
            ...brigade,
            employees: brigade.employees.filter((e) => e.id !== employee.id),
          };
        });
    };

    const addBrigade = (brigade) => {
      brigades.value.push({
        ...brigade,
        employees: [],
      });
    };

    const editBrigade = (brigade) => {
      brigades.value = brigades.value.map((old) => {
        if (old.id !== brigade.id) return old;
        return {
          ...old,
          ...brigade,
        };
      });
    };

    const deleteBrigade = (brigade) => {
      brigades.value = brigades.value.filter((b) => b.id !== brigade.id);
    };

    const employeeSelectable = (employee, brigadeId) => {
      if (!brigadeId) return true;

      const brigade = brigades.value.find((b) => b.id === brigadeId);
      if (!brigade) return true;

      return !brigade?.employees?.find((e) => e.cadre_employee_id === employee.id);
    };

    const investmentVehicleIds = computed(() => props.investment.base_resource
      ?.map((v) => v.base_resource_id) ?? []);

    return {
      investmentAssignEmployeeModal,
      investmentCreateBrigadeModal,
      populatedBrigades,

      addEmployee,
      deleteEmployee,
      editEmployee,

      addBrigade,
      editBrigade,
      deleteBrigade,

      employeeSelectable,
      investmentVehicleIds,
    };
  },
  components: {
    BButton,
    InvestmentsAssignEmployeeModal,
    InvestmentsCreateBrigadeModal,
    InvestmentsEditEmployeeModal,
    InvestmentsCadreDetailsList,
    InvestmentsEditBrigadeModal,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
.investments-cadre-details-list
  margin-top: 50px

.vs-form-header
  margin-top: 40px
  width: calc(100% + 15px)
  margin-left: -15px

.resource-button
  position: absolute
  padding: 7px 0

  @media screen and (max-width: 768px)
    padding: 25px 0

    &-cadre
      top: 90px

    &-brigade
      top: 120px

  &-cadre
    top: 70px

  &-brigade
    top: 100px
</style>
