<template>
  <div class="investments-resource-form">

    <b-button
      variant="link"
      class="resource-button resource-button-equipment"
      @click="investmentAssignResourceModal.visible = true"
      v-can:store="'investment.base_resource'"
      >
      + Dodaj maszynę lub sprzęt
    </b-button>

    <authorization-guard
      permission="investment.base_resource.index"
    >
      <investments-resource-details
        :resources="resources"
        :investment="investment"

        @add="res => $emit('addVehicle', res)"
        @edit="res => $emit('editVehicle', res)"
        @remove="resId => $emit('removeVehicle', resId)"
      />
    </authorization-guard>

  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import InvestmentsResourceDetails from '../ui/InvestmentsResourceDetails.vue';

export default {
  name: 'InvestmentsResourceForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const resources = [];

    const { investmentAssignResourceModal } = useInvestmentsStore();

    return { resources, investmentAssignResourceModal };
  },
  components: {
    BButton,
    InvestmentsResourceDetails,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
.vs-form-header
  margin-top: 40px
  width: calc(100% + 15px)
  margin-left: -15px

.resource-button
  position: absolute
  padding: 7px 0

  @media screen and (max-width: 768px)
    padding: 25px 0

    &-equipment
      top: 90px

  &-equipment
    top: 70px
</style>
