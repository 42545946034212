<template>
  <div class="tab-title">
    <div class="tab-title-icon">
      <feather-icon :icon="icon" size="22" />
    </div>

    <div class="tab-title-text">
      {{ title }}

      <div class="tab-title-text-unsaved" v-if="false">

        <div class="unsaved-icon">
          <feather-icon icon="InfoIcon" size="16" />
        </div>

        <div class="unsaved-text">
          {{ $t('global.form.unsaved') }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VsFormTab',
  props: {
    icon: String,
    title: String,
  },
};
</script>

<style lang="sass" scoped>
.tab-title
  display: flex
  align-items: center
  text-align: left
  padding: 5px 0
  margin: 5px 0

  &-icon
    background: #F8F8F8
    position: relative
    padding: 7px 0

  &-text
    margin-left: 10px

    @media screen and (max-width: 1500px)
      display: none

    &-unsaved
      font-size: 9pt
      color: #1b94f7
      display: flex
      align-items: center

      .unsaved-icon
        position: relative
        left: -3px

      .unsaved-text
        position: relative
        left: -5px
</style>
