<template>
  <div class="investments-demand-form">

    <b-button
      variant="link"
      class="resource-button resource-button-demand"
      @click="investmentCreateDemandModal.visible = true"
      v-can:store="'investment.requirement'"
      >
      + Utwórz nowe zapotrzebowanie
    </b-button>

    <b-button
      variant="link"
      class="resource-button resource-button-offer"
      @click="investmentCreateOfferModal.visible = true"
      v-can:store="'investment.requirement_offer'"
      >
      + Utwórz nową ofertę
    </b-button>

    <authorization-guard
      permission="investment.requirement.index"
    >
      <investments-demand-details
        v-for="material in materials"
        :key="material.id"

        :materialId="material.id"
        :unitId="material.core_unit_id"
        :material="material.name"

        :demands="material.demands"
        :offers="material.offers"

        @offer:update="editOffer"
        @materialDeleted="deleteDemand"
        @offerDeleted="deleteOffer"
      />
    </authorization-guard>

    <investments-create-demand-modal
      @success="addDemand"
      :investment-id="investment.id"
    />

    <investments-edit-demand-modal
      @success="editDemand"
    />

    <investments-create-offer-modal
      @success="addOffer"
      :investment-id="investment.id"
    />

    <investments-edit-offer-modal
      @success="editOffer"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { computed, inject, ref } from 'vue';
import i18n from '@/libs/i18n';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import InvestmentsDemandDetails from '../ui/InvestmentsDemandDetails.vue';
import InvestmentsCreateDemandModal from '../modals/InvestmentsCreateDemandModal.vue';
import InvestmentsEditDemandModal from '../modals/InvestmentsEditDemandModal.vue';
import InvestmentsCreateOfferModal from '../modals/InvestmentsCreateOfferModal.vue';
import InvestmentsEditOfferModal from '../modals/InvestmentsEditOfferModal.vue';

export default {
  name: 'InvestmentsDemandForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      investmentCreateDemandModal,
      investmentCreateOfferModal,
    } = useInvestmentsStore();

    const sampleDemands = ref(props.investment?.requirements);
    const sampleOffers = ref(props.investment?.requirement_offers);

    const addDemand = (demand) => {
      sampleDemands.value.push({ ...demand });
    };

    const editDemand = (edited) => {
      sampleDemands.value = sampleDemands.value
        .map((demand) => {
          if (edited.id !== demand.id) return demand;

          return { ...edited };
        });
    };

    const deleteDemand = (deleted) => {
      sampleDemands.value = sampleDemands.value.filter(({ id }) => id !== deleted.id);
    };

    const fetchInvestment = inject('fetchInvestment');
    const addOffer = () => {
      fetchInvestment();
    };

    const editOffer = (edited) => {
      sampleOffers.value = sampleOffers.value
        .map((offer) => {
          if (edited.id !== offer.id) return offer;

          return { ...edited };
        });
    };

    const deleteOffer = (deleted) => {
      sampleOffers.value = sampleOffers.value.filter(({ id }) => id !== deleted.id);
    };

    const materials = computed(() => {
      const demands = {};

      const groupByMaterial = (array, name) => array.forEach((element) => {
        if (!demands[element.investment_requirement_group_id]) {
          demands[element.investment_requirement_group_id] = {
            ...element.requirement_group,
          };
        }

        if (!demands[element.investment_requirement_group_id][name]) {
          demands[element.investment_requirement_group_id][name] = [];
        }

        demands[element.investment_requirement_group_id][name].push(element);

        return demands;
      });

      groupByMaterial(sampleDemands.value, 'demands');
      groupByMaterial(sampleOffers.value, 'offers');

      return Object.keys(demands).map((demand) => ({
        demands: [],
        offers: [],
        ...demands[demand],
      }));
    });

    const tableI18n = 'investments.form.investment.tab.subject.table';
    const cols = [
      {
        label: i18n.t(`${tableI18n}.technology`),
        field: 'name',
      },
      {
        label: i18n.t(`${tableI18n}.diameter`),
        field: 'diameter',
      },
      {
        label: i18n.t(`${tableI18n}.quantity`),
        field: 'quantity',
      },
      {
        label: i18n.t(`${tableI18n}.length`),
        field: 'length',
      },
      {
        label: i18n.t(`${tableI18n}.mb`),
        field: 'mb',
      },
      {
        label: i18n.t(`${tableI18n}.reinforcement`),
        field: 'reinforcement',
      },
    ];

    return {
      sampleDemands,
      sampleOffers,
      cols,
      investmentCreateDemandModal,
      investmentCreateOfferModal,
      materials,

      addDemand,
      editDemand,

      addOffer,
      editOffer,
      deleteOffer,

      deleteDemand,
    };
  },
  components: {
    BButton,
    InvestmentsDemandDetails,
    InvestmentsCreateDemandModal,
    InvestmentsEditDemandModal,
    InvestmentsCreateOfferModal,
    InvestmentsEditOfferModal,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>

.resource-button
  position: absolute
  padding: 7px 0

  @media screen and (max-width: 768px)
    padding: 25px 0

    &-demand
      top: 90px

    &-offer
      top: 120px

  &-demand
    top: 70px

  &-offer
    top: 100px

.vs-form-header
  width: calc(100% + 15px)
  margin-left: -15px

.subject-list
  margin-top: 40px
</style>
