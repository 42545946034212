<template>
  <div class="investments-create-demand-modal">
    <b-modal
      id="modal-investments-create-demand"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.demand.create.title')"

      v-model="investmentCreateDemandModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.demand.create.loading')"
        v-if="isLoading"
      />

      <investments-material-demand-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.demand.create.cancel')"
          :text-ok="$t('investments.modal.demand.create.confirm')"

          @ok="assignSubject"
          @cancel="investmentCreateDemandModal.visible = false"
        >
          <template #left>
            <b-form-checkbox v-model="continueEditing" name="check-button">
              Dodaj kolejne zapotrzebowanie
            </b-form-checkbox>
          </template>
        </vs-modal-footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import showToast from '@/libs/toasts';
import { CreateDemandError, CreateDemandSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsMaterialDemandForm from '../forms/InvestmentsMaterialDemandForm.vue';

export default {
  name: 'InvestmentsCreateDemandModal',
  props: ['investmentId'],
  setup(props, { emit }) {
    const { investmentCreateDemandModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields,
      assignFields, assignServerSideErrors,
    } = useValidatedForm({
      investment_requirement_group_id: 'required|integer',
      name: 'required',
      price: 'required|integer_or_double|positive',
      value: 'required|integer|positive',
    }, 'investments.form.investment.tab.demand.field');

    watch(() => investmentCreateDemandModal.value.visible, () => {
      fields.value.whole_period = true;

      const { data } = investmentCreateDemandModal.value;
      if (data && Object.keys(data).length === 0) return;

      assignFields({ ...investmentCreateDemandModal.value.data });

      investmentCreateDemandModal.value.data = {};
    });

    const continueEditing = ref(false);

    const assignSubject = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      const keep = {
        investment_requirement_group_id: fields.value.investment_requirement_group_id,
      };

      isLoading.value = true;

      useInvestmentsApi()
        .createRequirement({
          investment_id: props.investmentId,
          ...fields.value,
        })
        .then(({ data }) => {
          showToast(CreateDemandSuccess, { name: fields.value.name });
          emit('success', { ...data.data });

          resetFields();

          if (!continueEditing.value) {
            investmentCreateDemandModal.value.visible = false;
          } else {
            fields.value = { ...fields.value, ...keep };
          }

          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(CreateDemandError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentCreateDemandModal,
      isLoading,
      continueEditing,
      assignSubject,
    };
  },
  components: {
    VsLoader,
    BFormCheckbox,
    InvestmentsMaterialDemandForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
