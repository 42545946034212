import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

const { fetchPickerWorkGroups } = useInvestmentsApi();

export default defineStore('investmentsGroup', () => {
  const { state } = useRemoteResource('investments.group', fetchPickerWorkGroups);

  return {
    state,
  };
});
