<template>
  <div class="investments-create-brigade-modal">
    <b-modal
      id="modal-investments-create-brigade"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.brigade.create.title')"

      v-model="investmentCreateBrigadeModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.brigade.create.loading')"
        v-if="isLoading"
      />

      <investments-brigade-form
        v-model="fields"
        :feedback="feedback"
        :investmentVehicleIds="investmentVehicleIds"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.brigade.create.cancel')"
          :text-ok="$t('investments.modal.brigade.create.confirm')"

          @ok="assignResource"
          @cancel="investmentCreateBrigadeModal.visible = false"
        >
          <template #left>
            <b-form-checkbox v-model="continueEditing" name="check-button">
              Dodaj kolejną brygadę
            </b-form-checkbox>
          </template>
        </vs-modal-footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import showToast from '@/libs/toasts';
import { CreateBrigadeError, CreateBrigadeSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsBrigadeForm from '../forms/InvestmentsBrigadeForm.vue';

export default {
  name: 'InvestmentsAssignEmployeeModal',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    investmentVehicleIds: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { investmentCreateBrigadeModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields,
    } = useValidatedForm({
      name: 'required',
      is_management: '',
      investment_base_vehicles: '',
      main_vehicle: 'integer',
      additional_vehicles: '',
    }, 'investments.form.investment.tab.brigade.field');

    const continueEditing = ref(false);

    const findRelationForVehicle = (searchedId) => props.investment.base_resource
      .find((v) => v.base_resource_id === searchedId);

    const assignResource = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      isLoading.value = true;

      let vehicles = [];
      if (fields.value?.additional_vehicles?.length) {
        vehicles = [
          ...vehicles,
          ...fields.value.additional_vehicles.map((id) => ({
            id: findRelationForVehicle(id)?.id ?? null,
            is_main: false,
          })),
        ];
      }
      if (fields.value?.main_vehicle) {
        vehicles.push({
          id: findRelationForVehicle(fields.value.main_vehicle)?.id ?? null,
          is_main: true,
        });
      }

      useInvestmentsApi()
        .createBrigade({
          ...fields.value,
          is_management: !!fields.value.is_management,
          investment_id: props.investment.id,
          investment_base_resource: vehicles,
        })
        .then(({ data }) => {
          showToast(CreateBrigadeSuccess, { name: data.data.name });
          emit('success', data.data);

          if (!continueEditing.value) {
            investmentCreateBrigadeModal.value.visible = false;
          }

          resetFields();
          isLoading.value = false;
        })
        .catch(() => {
          showToast(CreateBrigadeError);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentCreateBrigadeModal,
      isLoading,
      continueEditing,
      assignResource,
    };
  },
  components: {
    VsLoader,
    BFormCheckbox,
    InvestmentsBrigadeForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
