var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-cadre-form"
  }, [_c('b-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'investment.brigade',
      expression: "'investment.brigade'",
      arg: "store"
    }],
    staticClass: "resource-button resource-button-brigade",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.investmentCreateBrigadeModal.visible = true;
      }
    }
  }, [_vm._v(" + Dodaj brygadę ")]), _c('b-button', {
    directives: [{
      name: "can",
      rawName: "v-can:assign_employee",
      value: 'investment.brigade',
      expression: "'investment.brigade'",
      arg: "assign_employee"
    }],
    staticClass: "resource-button resource-button-cadre",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.investmentAssignEmployeeModal.visible = true;
      }
    }
  }, [_vm._v(" + Dodaj pracownika ")]), _c('authorization-guard', {
    attrs: {
      "permission": "investment.brigade.index"
    }
  }, [_c('investments-cadre-details-list', {
    attrs: {
      "brigades": _vm.populatedBrigades
    },
    on: {
      "delete": _vm.deleteEmployee,
      "brigadeDelete": _vm.deleteBrigade
    }
  })], 1), _c('investments-assign-employee-modal', {
    attrs: {
      "employeeSelectable": _vm.employeeSelectable,
      "investment-id": _vm.investment.id
    },
    on: {
      "success": _vm.addEmployee
    }
  }), _c('investments-edit-employee-modal', {
    attrs: {
      "investment-id": _vm.investment.id
    },
    on: {
      "success": _vm.editEmployee
    }
  }), _c('investments-create-brigade-modal', {
    attrs: {
      "investment": _vm.investment,
      "investmentVehicleIds": _vm.investmentVehicleIds
    },
    on: {
      "success": _vm.addBrigade
    }
  }), _c('investments-edit-brigade-modal', {
    attrs: {
      "investment": _vm.investment,
      "investmentVehicleIds": _vm.investmentVehicleIds
    },
    on: {
      "success": _vm.editBrigade
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }