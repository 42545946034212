var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-assign-subject-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-investments-assign-subject",
      "cancel-variant": "outline-secondary",
      "size": "lg",
      "title": _vm.$t('investments.modal.subject.create.title'),
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        $event.preventDefault();
        return function () {}.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('vs-modal-footer', {
          attrs: {
            "is-loading": _vm.isLoading,
            "text-cancel": _vm.$t('investments.modal.subject.create.cancel'),
            "text-ok": _vm.$t('investments.modal.subject.create.confirm')
          },
          on: {
            "ok": _vm.assignSubject,
            "cancel": function ($event) {
              _vm.investmentAssignSubjectModal = false;
            }
          },
          scopedSlots: _vm._u([{
            key: "left",
            fn: function () {
              return [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button"
                },
                model: {
                  value: _vm.continueEditing,
                  callback: function ($$v) {
                    _vm.continueEditing = $$v;
                  },
                  expression: "continueEditing"
                }
              }, [_vm._v(" Dodaj kolejny przedmiot zamówienia ")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.investmentAssignSubjectModal,
      callback: function ($$v) {
        _vm.investmentAssignSubjectModal = $$v;
      },
      expression: "investmentAssignSubjectModal"
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": _vm.$t('investments.modal.subject.create.loading')
    }
  }) : _vm._e(), _c('investments-assign-subject-form', {
    attrs: {
      "feedback": _vm.feedback
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }