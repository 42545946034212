var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-material-demand-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.investment.tab.demand.field"
    },
    model: {
      value: _vm.subject,
      callback: function ($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "investment_requirement_group_id"
    }
  }, [_c('investments-material-picker', {
    attrs: {
      "reduce": function (m) {
        return m.id;
      }
    },
    on: {
      "pick": _vm.updateMaterialUnit
    },
    model: {
      value: _vm.subject.investment_requirement_group_id,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "investment_requirement_group_id", $$v);
      },
      expression: "subject.investment_requirement_group_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.demand.field.name')
    },
    model: {
      value: _vm.subject.name,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "name", $$v);
      },
      expression: "subject.name"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "price"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "PLN"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.demand.field.price')
    },
    model: {
      value: _vm.subject.price,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "price", $$v);
      },
      expression: "subject.price"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "value"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": _vm.materialUnitCode
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.demand.field.value')
    },
    model: {
      value: _vm.subject.value,
      callback: function ($$v) {
        _vm.$set(_vm.subject, "value", $$v);
      },
      expression: "subject.value"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }