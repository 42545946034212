var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-demand-form"
  }, [_c('b-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'investment.requirement',
      expression: "'investment.requirement'",
      arg: "store"
    }],
    staticClass: "resource-button resource-button-demand",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.investmentCreateDemandModal.visible = true;
      }
    }
  }, [_vm._v(" + Utwórz nowe zapotrzebowanie ")]), _c('b-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'investment.requirement_offer',
      expression: "'investment.requirement_offer'",
      arg: "store"
    }],
    staticClass: "resource-button resource-button-offer",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.investmentCreateOfferModal.visible = true;
      }
    }
  }, [_vm._v(" + Utwórz nową ofertę ")]), _c('authorization-guard', {
    attrs: {
      "permission": "investment.requirement.index"
    }
  }, _vm._l(_vm.materials, function (material) {
    return _c('investments-demand-details', {
      key: material.id,
      attrs: {
        "materialId": material.id,
        "unitId": material.core_unit_id,
        "material": material.name,
        "demands": material.demands,
        "offers": material.offers
      },
      on: {
        "offer:update": _vm.editOffer,
        "materialDeleted": _vm.deleteDemand,
        "offerDeleted": _vm.deleteOffer
      }
    });
  }), 1), _c('investments-create-demand-modal', {
    attrs: {
      "investment-id": _vm.investment.id
    },
    on: {
      "success": _vm.addDemand
    }
  }), _c('investments-edit-demand-modal', {
    on: {
      "success": _vm.editDemand
    }
  }), _c('investments-create-offer-modal', {
    attrs: {
      "investment-id": _vm.investment.id
    },
    on: {
      "success": _vm.addOffer
    }
  }), _c('investments-edit-offer-modal', {
    on: {
      "success": _vm.editOffer
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }