<template>
  <div class="investments-assign-employee-modal">
    <b-modal
      id="modal-investments-assign-employee"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.employee.create.title')"

      v-model="investmentAssignEmployeeModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.employee.create.loading')"
        v-if="isLoading"
      />

      <investments-assign-employee-form
        v-model="fields"
        :feedback="feedback"
        :employeeSelectable="(employee) => employeeSelectable(employee, fields.brigade_id)"
        :investment-id="investmentId"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.employee.create.cancel')"
          :text-ok="$t('investments.modal.employee.create.confirm')"

          @ok="assignResource"
          @cancel="investmentAssignEmployeeModal.visible = false"
        >
          <template #left>
            <b-form-checkbox v-model="continueEditing" name="check-button">
              Dodaj kolejnego pracownika
            </b-form-checkbox>
          </template>
        </vs-modal-footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import showToast from '@/libs/toasts';
import { AssignEmployeeError, AssignEmployeeSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsAssignEmployeeForm from '../forms/InvestmentsAssignEmployeeForm.vue';

export default {
  name: 'InvestmentsAssignEmployeeModal',
  props: {
    employeeSelectable: {
      type: Function,
      default: () => true,
    },
    investmentId: {
      type: Number,
    },
  },
  setup(_, { emit }) {
    const { investmentAssignEmployeeModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, resetFields, assignFields,
      assignServerSideErrors, nonEmptyFields,
    } = useValidatedForm({
      brigade_id: 'required|integer',
      cadre_employee_id: 'required|integer',
      cadre_position_id: 'required|integer',
      resource_data: {
        scheduler: {
          start_date: 'required',
          end_date: 'required|date_after:@start_date',
        },
        days_number: 'required',
      },
    }, 'investments.form.investment.tab.cadre.field');

    watch(() => investmentAssignEmployeeModal.value.visible, () => {
      const { data } = investmentAssignEmployeeModal.value;
      if (data && Object.keys(data).length === 0) return;

      const editingValues = {
        ...investmentAssignEmployeeModal.value.data,
      };

      assignFields(editingValues);

      investmentAssignEmployeeModal.value.data = {};
    });

    const continueEditing = ref(false);

    const assignResource = async () => {
      isLoading.value = true;

      useInvestmentsApi()
        .assignEmployee(fields.value.brigade_id, {
          ...nonEmptyFields.value,
        })
        .then(({ data }) => {
          showToast(AssignEmployeeSuccess, { name: fields.value.work_days });
          emit('success', data.data);

          if (!continueEditing.value) {
            investmentAssignEmployeeModal.value.visible = false;
          }

          resetFields();
          isLoading.value = false;
        })
        .catch(({ response }) => {
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
          showToast(AssignEmployeeError);
        });
    };

    return {
      fields,
      feedback,
      investmentAssignEmployeeModal,
      isLoading,
      continueEditing,
      assignResource,
    };
  },
  components: {
    VsLoader,
    BFormCheckbox,
    InvestmentsAssignEmployeeForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
