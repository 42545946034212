var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-resource-form"
  }, [_c('b-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'investment.base_resource',
      expression: "'investment.base_resource'",
      arg: "store"
    }],
    staticClass: "resource-button resource-button-equipment",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.investmentAssignResourceModal.visible = true;
      }
    }
  }, [_vm._v(" + Dodaj maszynę lub sprzęt ")]), _c('authorization-guard', {
    attrs: {
      "permission": "investment.base_resource.index"
    }
  }, [_c('investments-resource-details', {
    attrs: {
      "resources": _vm.resources,
      "investment": _vm.investment
    },
    on: {
      "add": function (res) {
        return _vm.$emit('addVehicle', res);
      },
      "edit": function (res) {
        return _vm.$emit('editVehicle', res);
      },
      "remove": function (resId) {
        return _vm.$emit('removeVehicle', resId);
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }