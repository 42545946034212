<template>
  <div class="investments-edit-demand-modal">
    <b-modal
      id="modal-investments-edit-demand"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.demand.edit.title')"

      v-model="investmentEditDemandModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.demand.edit.loading')"
        v-if="isLoading"
      />

      <investments-material-demand-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.demand.edit.cancel')"
          :text-ok="$t('investments.modal.demand.edit.confirm')"

          @ok="editDemand"
          @cancel="investmentEditDemandModal.visible = false"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { EditDemandError, EditDemandSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsMaterialDemandForm from '../forms/InvestmentsMaterialDemandForm.vue';

export default {
  name: 'InvestmentsEditDemandModal',
  props: {},
  setup(_props, { emit }) {
    const { investmentEditDemandModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const requirement = ref(null);

    const {
      fields, feedback, validateAllFields, resetFields, assignFields,
      nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      id: 'integer',
      investment_requirement_group_id: 'required|integer',
      name: 'required',
      price: 'required|integer_or_double|positive',
      value: 'required|integer|positive',
    }, 'investments.form.investment.tab.demand.field');

    watch(investmentEditDemandModal.value, () => {
      const { data } = investmentEditDemandModal.value;
      if (data && Object.keys(data).length === 0) return;

      const editingValues = { ...investmentEditDemandModal.value.data };

      requirement.value = {
        id: investmentEditDemandModal.value.data?.id,
        investment_id: investmentEditDemandModal.value.data?.investment_id,
      };

      assignFields(editingValues);

      investmentEditDemandModal.value.data = {};
    });

    const editDemand = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;
      if (!requirement.value.id) return;

      isLoading.value = true;

      useInvestmentsApi()
        .updateRequirement(requirement.value.id, {
          ...nonEmptyFields.value,
          investment_id: requirement.value.investment_id,
          value: `${fields.value.value}`,
        })
        .then(({ data }) => {
          showToast(EditDemandSuccess, { name: data.data.name });
          emit('success', data.data);

          resetFields();
          investmentEditDemandModal.value.visible = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(EditDemandError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentEditDemandModal,
      isLoading,
      editDemand,
    };
  },
  components: {
    VsLoader,
    InvestmentsMaterialDemandForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between

  .buttons
    margin-left: auto
</style>
