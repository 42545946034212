var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-assign-cadre-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.investment.tab.cadre.field"
    },
    model: {
      value: _vm.cadre,
      callback: function ($$v) {
        _vm.cadre = $$v;
      },
      expression: "cadre"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "brigade_id"
    }
  }, [_c('investments-brigade-picker', {
    attrs: {
      "reduce": function (brigade) {
        return brigade.id;
      },
      "disabled": _vm.editing,
      "investment-id": _vm.investmentId
    },
    model: {
      value: _vm.cadre.brigade_id,
      callback: function ($$v) {
        _vm.$set(_vm.cadre, "brigade_id", $$v);
      },
      expression: "cadre.brigade_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "cadre_employee_id",
      "integration": "cadre_employee_relation"
    }
  }, [!_vm.editing ? _c('cadre-employee-picker', {
    attrs: {
      "reduce": function (e) {
        return e.id;
      },
      "selectable": _vm.employeeSelectable
    },
    model: {
      value: _vm.cadre.cadre_employee_id,
      callback: function ($$v) {
        _vm.$set(_vm.cadre, "cadre_employee_id", $$v);
      },
      expression: "cadre.cadre_employee_id"
    }
  }) : _c('cadre-employee-label', {
    attrs: {
      "id": _vm.cadre.cadre_employee_id
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "cadre_position_id",
      "integration": "cadre_position_relation"
    }
  }, [_c('cadre-position-picker', {
    on: {
      "pick": _vm.setPosition
    },
    model: {
      value: _vm.cadre.cadre_position_id,
      callback: function ($$v) {
        _vm.$set(_vm.cadre, "cadre_position_id", $$v);
      },
      expression: "cadre.cadre_position_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "start_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.cadre.resource_data.scheduler.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.cadre.resource_data.scheduler, "start_date", $$v);
      },
      expression: "cadre.resource_data.scheduler.start_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "end_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.cadre.resource_data.scheduler.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.cadre.resource_data.scheduler, "end_date", $$v);
      },
      expression: "cadre.resource_data.scheduler.end_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "days_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.tab.cadre.field.days_number')
    },
    model: {
      value: _vm.cadre.resource_data.days_number,
      callback: function ($$v) {
        _vm.$set(_vm.cadre.resource_data, "days_number", $$v);
      },
      expression: "cadre.resource_data.days_number"
    }
  }), _vm.totalCost ? _c('div', {
    staticClass: "total-cost"
  }, [_c('feather-icon', {
    staticClass: "total-cost-icon",
    attrs: {
      "icon": "InfoIcon",
      "size": "18"
    }
  }), _c('div', {
    staticClass: "total-cost-text"
  }, [_vm._v(" Całkowity koszt pracy wyniesie "), _c('span', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.totalCost.toLocaleString()) + " PLN")])])], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }