<template>
  <div class="investments-resource-details">
    <investments-resource-details-list
      :investment="investment"
      :resources="localResources"
      @materialDeleted="onResourceDelete"
    />

    <investments-assign-resource-modal
      @success="addResource"
      :investment-id="investment.id"
    />
    <investments-edit-resource-modal
      @success="editResource"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import InvestmentsEditResourceModal from '../modals/InvestmentsEditResourceModal.vue';
import InvestmentsAssignResourceModal from '../modals/InvestmentsAssignResourceModal.vue';
import InvestmentsResourceDetailsList from './InvestmentsResourceDetailsList.vue';

export default {
  name: 'InvestmentsResourceDetails',
  props: {
    resources: {
      type: Array,
      default: () => [],
    },
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { investmentAssignResourceModal } = useInvestmentsStore();

    const localResources = ref([
      ...props.investment?.base_resource,
    ]);

    const onResourceDelete = (material) => {
      const deletedMaterialIdx = localResources.value.map((r) => r.id).indexOf(material.id);
      localResources.value.splice(deletedMaterialIdx, 1);
      emit('remove', material.id);
    };

    const addResource = (resource) => {
      localResources.value.push({
        ...resource,
      });

      emit('add', resource);
    };

    const editResource = (resource) => {
      localResources.value = localResources.value.map((oldRes) => {
        if (oldRes.id !== resource.id) return oldRes;
        return {
          ...oldRes,
          ...resource,
        };
      });

      emit('edit', resource);
    };

    return {
      investmentAssignResourceModal,
      localResources,

      addResource,
      editResource,
      onResourceDelete,
    };
  },
  components: {
    InvestmentsAssignResourceModal,
    InvestmentsEditResourceModal,
    InvestmentsResourceDetailsList,
  },
};
</script>
