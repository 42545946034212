<template>
  <div class="investments-edit-brigade-modal">
    <b-modal
      id="modal-investments-edit-brigade"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.brigade.edit.title')"

      v-model="investmentEditBrigadeModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.brigade.edit.loading')"
        v-if="isLoading"
      />

      <investments-brigade-form
        v-model="fields"
        :feedback="feedback"
        :investmentVehicleIds="investmentVehicleIds"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.brigade.edit.cancel')"
          :text-ok="$t('investments.modal.brigade.edit.confirm')"

          @ok="assignResource"
          @cancel="investmentEditBrigadeModal.visible = false"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { EditBrigadeError, EditBrigadeSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsBrigadeForm from '../forms/InvestmentsBrigadeForm.vue';

export default {
  name: 'InvestmentsAssignEmployeeModal',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    investmentVehicleIds: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { investmentEditBrigadeModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields, assignFields,
    } = useValidatedForm({
      id: '',
      name: 'required',
      is_management: '',
      investment_base_vehicles: '',
      main_vehicle: 'integer',
      additional_vehicles: '',
    }, 'investments.form.investment.tab.brigade.field');

    const continueEditing = ref(false);

    watch(() => investmentEditBrigadeModal.value.visible, () => {
      if (!investmentEditBrigadeModal.value.visible) {
        resetFields();
        return;
      }
      const { data } = investmentEditBrigadeModal.value;
      if (data && Object.keys(data).length === 0) return;

      const editingValues = {
        ...investmentEditBrigadeModal.value.data,
        is_management: !!investmentEditBrigadeModal.value.data?.is_management,
      };

      const getVehicleId = (v) => {
        if (!v) return null;
        return v.base_resource_id;
      };

      const mainVehicle = getVehicleId(editingValues.resource
        ?.find(({ pivot }) => pivot.is_main));

      const additionalVehicles = editingValues.resource
        ?.filter((v) => v && !v.pivot.is_main)
        ?.map((v) => getVehicleId(v)) ?? [];

      assignFields({
        ...editingValues,
        main_vehicle: mainVehicle ?? null,
        additional_vehicles: additionalVehicles || [],
      });

      fields.value.additional_vehicles = additionalVehicles;

      investmentEditBrigadeModal.value.data = {};
    });

    const findRelationForVehicle = (searchedId) => props.investment.base_resource
      .find((v) => v.base_resource_id === searchedId);

    const assignResource = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;
      if (!fields.value.id) return;

      isLoading.value = true;
      let vehicles = [];
      if (fields.value?.additional_vehicles?.length) {
        vehicles = [
          ...vehicles,
          ...fields.value.additional_vehicles.map((id) => ({
            id: findRelationForVehicle(id)?.id ?? null,
            is_main: false,
          })),
        ];
      }
      if (fields.value?.main_vehicle) {
        vehicles.push({
          id: findRelationForVehicle(fields.value.main_vehicle)?.id ?? null,
          is_main: true,
        });
      }

      useInvestmentsApi()
        .editBrigade(fields.value.id, {
          investment_id: props.investment.id,
          investment_base_resource: vehicles.length ? vehicles : null,
          is_management: fields.value.is_management,
          name: fields.value.name,
        })
        .then(({ data }) => {
          showToast(EditBrigadeSuccess, { name: data.data.name });
          emit('success', data.data);

          if (!continueEditing.value) {
            investmentEditBrigadeModal.value.visible = false;
          }

          resetFields();
          isLoading.value = false;
        })
        .catch(() => {
          showToast(EditBrigadeError);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentEditBrigadeModal,
      isLoading,
      continueEditing,
      assignResource,
    };
  },
  components: {
    VsLoader,
    InvestmentsBrigadeForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
