<template>
  <div class="investments-edit-subject-modal">
    <b-modal
      id="modal-investments-edit-subject"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.subject.edit.title')"

      v-model="investmentEditSubjectModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.subject.edit.loading')"
        v-if="isLoading"
      />

      <investments-assign-subject-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.subject.edit.cancel')"
          :text-ok="$t('investments.modal.subject.edit.confirm')"

          @ok="assignSubject"
          @cancel="investmentEditSubjectModal.visible = false"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { EditSubjectSuccess, EditSubjectError } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsAssignSubjectForm from '../forms/InvestmentsAssignSubjectForm.vue';

export default {
  name: 'InvestmentsAssignSubjectModal',
  setup(_props, { emit }) {
    const { investmentEditSubjectModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields,
      assignFields, nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      id: 'integer',
      investment_id: 'integer',
      base_technology_id: 'required|integer',
      diameter: 'required|integer_or_double|positive',
      length: 'required|integer_or_double|positive',
      quantity: 'required|integer|positive',
      investment_subject_reinforcement_id: 'required|integer',
    }, 'investments.form.investment.tab.subject.field');

    watch(() => investmentEditSubjectModal.value.visible, () => {
      const { data } = investmentEditSubjectModal.value;
      if (data && Object.keys(data).length === 0) return;

      const editingValues = {
        ...investmentEditSubjectModal.value.data,
      };

      assignFields(editingValues);
      investmentEditSubjectModal.value.data = {};
    });

    const continueEditing = ref(false);

    const assignSubject = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;
      if (!fields.value.id) return;

      isLoading.value = true;

      useInvestmentsApi()
        .updateSubject(fields.value.id, { ...nonEmptyFields.value })
        .then(({ data }) => {
          // TODO: api request and proper redirect (id)
          showToast(EditSubjectSuccess);
          emit('success', data.data);

          if (!continueEditing.value) {
            investmentEditSubjectModal.value.visible = false;
          }

          resetFields();
          isLoading.value = false;
        })
        .catch(({ response }) => {
          assignServerSideErrors(response?.data?.errors);
          showToast(EditSubjectError);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentEditSubjectModal,
      isLoading,
      continueEditing,
      assignSubject,
    };
  },
  components: {
    VsLoader,
    InvestmentsAssignSubjectForm,
    VsModalFooter,
  },
};
</script>
