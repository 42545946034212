<template>
  <div class="investments-create-offer-modal">
    <b-modal
      id="modal-investments-create-offer"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.offer.create.title')"

      v-model="investmentCreateOfferModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.offer.create.loading')"
        v-if="isLoading"
      />

      <investments-material-offer-form
        v-model="fields"
        :feedback="feedback"
      />

      <div class="attachments-wrapper">
        <attachments useProps
          hideAccessInfo
          hideDate
          additionalInfo="Dodane załączniki zostaną przesłane dopiero po utworzeniu oferty"
          :items="files"
          @add="addAttachment"
        />
      </div>

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.offer.create.cancel')"
          :text-ok="$t('investments.modal.offer.create.confirm')"

          @ok="assignSubject"
          @cancel="investmentCreateOfferModal.visible = false"
        >
          <template #left>
            <b-form-checkbox v-model="continueEditing" name="check-button">
              Dodaj kolejną ofertę
            </b-form-checkbox>
          </template>
        </vs-modal-footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { reactive, ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import showToast from '@/libs/toasts';
import { CreateOfferError, CreateOfferSuccess, AttachmentSizeError } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import InvestmentsMaterialOfferForm from '../forms/InvestmentsMaterialOfferForm.vue';

export default {
  name: 'InvestmentsCreateOfferModal',
  props: ['investmentId'],
  setup(props, { emit }) {
    const { investmentCreateOfferModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields, validateField,
      assignFields, assignServerSideErrors, nonEmptyFields,
    } = useValidatedForm({
      investment_requirement_group_id: 'required|integer',
      crm_provider_id: 'required|integer',
      provider: '',
      valid_date: 'required',
      guarantee_price_date: 'required_or:@whole_period',
      whole_period: 'required_or:@guarantee_price_date',
      value: 'required|integer_or_double|positive',
    }, 'investments.form.investment.tab.offer.field');

    const continueEditing = ref(false);

    const formData = new FormData();

    let files = reactive([]);
    const addAttachment = (attachment) => {
      files.push(attachment);
    };

    watch(() => fields.value.whole_period, () => validateField('guarantee_price_date'));
    watch(() => investmentCreateOfferModal.value.visible, () => {
      fields.value.whole_period = true;

      const { data } = investmentCreateOfferModal.value;
      if (data && Object.keys(data).length === 0) return;

      assignFields({ ...investmentCreateOfferModal.value.data });
      files = [];

      investmentCreateOfferModal.value.data = {};
    });

    const assignSubject = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      const keep = {
        investment_requirement_group_id: fields.value.investment_requirement_group_id,
        unit_id: fields.value.unit_id,
        whole_period: fields.value.whole_period,
      };

      isLoading.value = true;

      formData.append('investment_id', props.investmentId);

      Object.keys(fields?.value).map((x) => {
        if (x === 'files') return false;
        const name = nonEmptyFields?.value?.[x];
        if (name === undefined) return false;

        formData.append(x, (name === null) ? '' : name);
        return true;
      });

      files.forEach(({ file }, idx) => {
        formData.append(`files[${idx}]`, file);
      });

      useInvestmentsApi()
        .createOffer(formData)
        .then(({ data }) => {
          showToast(CreateOfferSuccess);
          emit('success', { ...data.data });

          resetFields();

          if (!continueEditing.value) {
            investmentCreateOfferModal.value.visible = false;
          } else {
            fields.value = { ...fields.value, ...keep };
          }

          isLoading.value = false;
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(CreateOfferError);
          }
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      investmentCreateOfferModal,
      isLoading,
      continueEditing,
      assignSubject,
      validateField,
      addAttachment,
      files,
    };
  },
  components: {
    VsLoader,
    BFormCheckbox,
    InvestmentsMaterialOfferForm,
    VsModalFooter,
    Attachments,
  },
};
</script>

<style lang="sass" scoped>
.attachments-wrapper
  padding: 10px 40px

.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
