<template>
  <div class="investments-assign-cadre-form">
    <vs-form
      v-model="cadre"
      :errors="errors"
      i18nNamespace="investments.form.investment.tab.cadre.field"
    >

      <vs-form-group
        field="brigade_id"
        >
        <investments-brigade-picker
          v-model="cadre.brigade_id"
          :reduce="brigade => brigade.id"
          :disabled="editing"
          :investment-id="investmentId"
        />
      </vs-form-group>

      <vs-form-group
        field="cadre_employee_id"
        integration="cadre_employee_relation"
        >
        <cadre-employee-picker
          v-if="!editing"
          :reduce="e => e.id"
          v-model="cadre.cadre_employee_id"
          :selectable="employeeSelectable"
        />

        <cadre-employee-label
          v-else
          :id="cadre.cadre_employee_id"
        />
      </vs-form-group>

      <vs-form-group
        field="cadre_position_id"
        integration="cadre_position_relation"
        >
        <cadre-position-picker
          v-model="cadre.cadre_position_id"
          @pick="setPosition"
        />
      </vs-form-group>

      <vs-form-group
        field="start_date"
        >
        <vs-datepicker
          placeholder="Wybierz datę"
          v-model="cadre.resource_data.scheduler.start_date"
        />
      </vs-form-group>

      <vs-form-group
        field="end_date"
        >
        <vs-datepicker
          placeholder="Wybierz datę"
          v-model="cadre.resource_data.scheduler.end_date"
        />
      </vs-form-group>

      <vs-form-group
        field="days_number"
        >
        <b-form-input
          :placeholder="$t(
            'investments.form.investment.tab.cadre.field.days_number'
          )"
          v-model="cadre.resource_data.days_number"
        />

        <div class="total-cost" v-if="totalCost">
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="total-cost-icon"
          />

          <div class="total-cost-text">
            Całkowity koszt pracy wyniesie
            <span class="value"> {{ totalCost.toLocaleString() }} PLN</span>
          </div>

        </div>

      </vs-form-group>

    </vs-form>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue';
import {
  computed, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import InvestmentsBrigadePicker from '@/components/ui/pickers/investments/InvestmentsBrigadePicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import CadrePositionPicker from '@/components/module/cadre/CadrePositionPicker.vue';
import { daysCountBetweenDates } from '@/libs/date-formatter';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import CadreEmployeeLabel from '../../cadre/ui/CadreEmployeeLabel.vue';

export default {
  name: 'InvestmentsAssignEmployeeForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    editing: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    employeeSelectable: {
      type: Function,
      default: () => true,
    },
    investmentId: {
      type: Number,
    },
  },
  setup(props) {
    const cadre = computed(() => props.value);
    const errors = ref({});

    const position = ref({});
    const setPosition = (val) => { position.value = val; };

    const totalCost = computed(() => {
      const dailyCost = position?.value?.actual_daily_cost ?? 0;
      const days = props.value.resource_data?.days_number ?? 0;

      return dailyCost * days;
    });

    const startDate = computed(() => cadre.value.resource_data?.scheduler?.start_date);
    const endDate = computed(() => cadre.value.resource_data?.scheduler?.end_date);

    watch([
      () => startDate.value,
      () => endDate.value,
    ], () => {
      const daysCount = daysCountBetweenDates(startDate.value, endDate.value, [0, 6]);
      cadre.value.resource_data.days_number = daysCount;
    });

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    return {
      cadre,
      errors,
      totalCost,
      setPosition,
    };
  },
  components: {
    BFormInput,
    VsForm,
    VsFormGroup,
    CadreEmployeePicker,
    InvestmentsBrigadePicker,
    CadreEmployeeLabel,
    CadrePositionPicker,
    VsDatepicker,
  },
};
</script>

<style lang="sass" scoped>
.total-cost
  margin-top: 15px
  display: flex
  align-items: center
  position: relative
  color: #3d7de3

  &-icon
    margin-right: 8px

  .value
    font-weight: 600

.editable-spans
  display: flex
  margin-top: 5px
  font-size: 12pt

  input
    width: 1em

  span
    display: block
    position: relative
    opacity: 0.7
    min-width: 10px

    &:empty
      min-width: 50px
      background: rgba(128, 128, 128, 0.1)
      border-radius: 5px

    &[contenteditable="true"]
      opacity: 1
      font-weight: 400

      &:focus
        outline: none
        font-weight: 500

      &:not(:empty):after
        left: 0
        display: block
        content: ''
        position: absolute
        background: orange
        width: 100%
        height: 1px

.serial-persistent
  text-align: center
  width: 10px
</style>
